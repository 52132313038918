.hdg-lv3 {
  color: palette('corp');
  font-weight: normal;
  font-size: 2.4rem;
  margin: 30px 0 0;
  padding: 0 0 0 10px;
  border-left: 3px solid palette('corp');

  @include below($sm) {
    font-size: 1.8rem;
    margin: 35px 0 0;
  }

  .hdg-lv2 + & {
    @include below($sm) {
      margin: 20px 0 0;
    }
  }
}