.panel-schedule {
  border: 1px solid palette('corp');

  @include below($sm) {
    border-right: 0;
    border-left: 0;
  }

  > .hdg {
    margin: 0;
    color: #fff;
    background: palette('corp');
    font-size: 1.8rem;
    padding: 14px 18px;

    @include below($sm) {
      font-size: 1.6rem;
      padding: 10px;
    }

    &::before {
      content: '';
      display: inline-block;
      @include retina-sprite($icon-schedule-white-group);
      vertical-align: middle;
      margin-right: 10px;

      @include below($sm) {
        transform: scale(0.7);
        margin-right: 3px;
      }
    }
  }

  > ol {
    padding: 20px 10px 35px 20px;
    position: relative;

    &::before {
      content: '';
      display: block;
      width: 3px;
      height: 100%;
      background: #d3ddf2;
      position: absolute;
      top: 0;
      left: 40px;
    }

    > li {
      margin: 15px 0 0;
      display: flex;
      line-height: 1.4;
      position: relative;
      z-index: 10;

      &.mt {
        margin-top: 35px;
      }

      > .time {
        display: block;
        width: 65px;
        font-weight: bold;
        color: palette('corp');
      }

      > .cont {
        display: block;
        width: calc(100% - 65px);
      }
    }
  }
}