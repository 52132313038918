@charset "UTF-8";

// 要素やコンポーネントの共通上マージンを一括管理
// -----------------------------------------------------------
// 原則、プレースホルダを extend で使用する
%mt {
  margin: 1.4em 0 0;
}

._mt {
  @extend %mt;
}