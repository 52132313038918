.hdg-newstitle {
  text-align: center;
  font-weight: bold;
  font-size: 2.6rem;
  margin: 50px 0 45px;

  > span {
    display: inline-block;
    padding: 0 0 5px;
    border-bottom: 1px solid palette('corp');
  }
}