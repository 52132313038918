.top-hero {
  position: relative;

  @include below($sm) {
    display: none;
  }

  .logo {
    position: absolute;
    top: -4px;
    left: 50%;
    z-index: 1000;
    margin: 0;
    transform: translateX(-50%);
  }

  .slogan {
    position: absolute;
    top: 50%;
    left: 50%;
    margin: 0;
    transform: translate(-50%, -50%);
    z-index: 1000;
  }

  .scroll {
    position: absolute;
    bottom: 20px;
    left: 50%;
    z-index: 1000;
    margin: 0;
    transform: translateX(-50%);

    a {
      &:hover,
      &:active,
      &:focus {
        opacity: 0.5;
      }
    }
  }

  .panelwrap {
    width: 100%;
    height: calc(100vh - 95px);
    display: flex;
    flex-wrap: wrap;
    position: relative;
    overflow: hidden;

    > .panel {
      width: calc(100% / 2);
      position: relative;

      &::before {
        content: '';
        display: block;
        width: 100%;
        height: 100%;
        background: url('../img/top-hero_panel_pattern.png') 0 0 repeat;
        position: absolute;
        top: 0;
        left: 0;
        z-index: 500;
      }

      .img {
        display: block;
        width: 100%;
        height: 100%;
        background-position: 50% 50%;
        background-repeat: no-repeat;
        background-size: cover;
      }

      .slick-list {
        height: 100%;
      }

      .slick-track {
        height: 100%;
      }
    }
  }
}