.hdg-page {
  text-align: center;
  font-weight: normal;
  color: palette('corp');
  position: relative;
  padding: 0 0 15px;
  margin: 25px 0 45px;

  @include below($sm) {
    margin: 0 0 30px;
    padding: 0 0 10px;
  }

  &::after {
    content: '';
    display: block;
    width: 100px;
    height: 1px;
    background: palette('corp');
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);

    @include below($sm) {
      width: 50px;
    }
  }

  > .ja {
    display: block;
    font-size: 3.6rem;
    letter-spacing: 0.08em;

    @include below($sm) {
      font-size: 2.4rem;
    }
  }

  > .en {
    display: block;
    font-size: 1.8rem;
    letter-spacing: 0.3em;
    margin: 3px 0 0;

    @include below($sm) {
      font-size: 1.2rem;
    }
  }
}