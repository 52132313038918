.top-button {
  margin: 50px 0 0;

  @include below($sm) {
    margin: 35px 0 0;
  }

  > ul {

    > li {
      margin: 0 auto 15px;
      width: 285px;

      @include below($sm) {
        width: 250px;
      }
    }
  }
}