.table-simple {
  @extend %mt;
  border-collapse: collapse;
  width: 100%;
  box-sizing: border-box;

  @include below($sm) {
    margin: 35px -15px 0;
    display: block;
    width: auto;
  }

  tbody {
    @include below($sm) {
      display: block;
    }
  }

  tr {
    @include below($sm) {
      display: block;
    }
  }

  th,
  td {
    padding: 15px 30px;

    @include below($sm) {
      display: block;
    }
  }

  th {
    background: #cbd2e8;
    border-width: 1px 0;
    border-style: solid;
    border-color: #fff;
    font-weight: normal;

    @include below($sm) {
      padding: 10px 15px;
      width: auto !important;
      border: none;
      text-align: left;
      font-size: 1.6rem;
    }
  }

  td {
    border-width: 1px 0;
    border-style: dotted;
    border-color: #acacac;

    @include below($sm) {
      width: auto !important;
      padding: 20px 15px;
      border-top: none;
    }

    & + td {
      border-left: 1px dotted #acacac;

      @include below($sm) {
        border-left: none;
      }
    }
  }

  .hdg-lv2 + & {
    @include below($sm) {
      margin-top: 15px;
    }
  }
}