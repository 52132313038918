.go-pagetop {
  position: fixed;
  bottom: 0;
  right: 1px;
  transition: opacity 0.5s $ease-out-quint;

  @include above($sm) {
    opacity: 0;
    visibility: hidden;
  }

  @include below($sm) {
    position: static !important;
  }

  &.-show {
    opacity: 1;
    visibility: visible;
  }

  &.-atbottom {
    position: absolute;
    right: 1px;
    bottom: 111px;
  }

  > a {
    display: flex;
    width: 50px;
    height: 50px;
    background: palette('corp');
    color: #fff;
    text-decoration: none;
    justify-content: center;
    align-items: center;
    transition: background 0.15s $ease-out-quint;

    @include below($sm) {
      background: #555;
      width: auto;
      height: auto;
      display: block;
      text-align: center;
      line-height: 1.2;
      padding: 10px;
    }

    &::before {
      content: '';
      display: block;
      @include icon-ion('f3d8');
      font-size: 3rem;

      @include below($sm) {
        font-size: 2.7rem;
        line-height: 1;
      }
    }

    &:hover,
    &:active,
    &:focus {
      background: palette('corp', 'light');

      @include below($sm) {
        background: lighten(#555, 10%);
      }
    }

    > span {
      text-indent: -9999px;

      @include below($sm) {
        // text-indent: 0;
        // display: block;
        // font-size: 1.3rem;
        display: none;
      }
    }
  }
}