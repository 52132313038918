.hdg-lv2 {
  color: palette('corp');
  font-weight: normal;
  font-size: 2.6rem;
  border-bottom: 1px solid #333;
  padding: 0 0 5px;
  margin: 60px 0 0;

  @include below($sm) {
    font-size: 2rem;
    margin: 35px 0 0;
  }

  .table-simple + & {
    @include below($sm) {
      margin-top: 55px;
    }
  }
}