@charset "UTF-8";

// 特定幅でのみの表示用ユーティリティクラス
// -----------------------------------------------------------
// PC 幅でのみ表示する要素へのユーティリティクラス
._pconly {
  @include below($sm) {
    display: none !important;
  }
}

// モバイル幅でのみ表示する要素へのユーティリティクラス
._sponly {
  @include above($sm) {
    display: none !important;
  }
}