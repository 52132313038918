.header-leftaddress {
  padding-right: 105px;
  font-size: 1.2rem;

  @include above($sm) {
    display: none;
  }

  > p {
    margin: 10px 0 0;
    line-height: 1.4;
    color: palette('corp');
  }
}