.nav-local {
  margin: 85px 0 0;

  @include below($sm) {
    margin: 50px -15px -45px;
  }

  > .navs {
    display: flex;
    justify-content: space-between;
    line-height: 1.4;

    @include below($sm) {
      flex-wrap: wrap;
    }

    > li {
      display: flex;

      > a {
        display: flex;
        justify-content: center;
        align-items: center;
      }

      br:not([class="_pc"]) {
        @include above($sm) {
          display: none;
        }
      }
    }

    &.-col4 {
      > li {
        width: calc((100% - 1px * 3) / 4);

        @include below($sm) {
          width: calc((100% - 1px * 1) / 2);
          margin-top: 1px;
        }
      }
    }
  }

  .panel-foot + & {
    margin-top: 45px;
    @include below($sm) {
      margin-top: 35px;
    }
  }
}