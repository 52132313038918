@charset "UTF-8";

// margin 調整用ユーティリティクラス
// -----------------------------------------------------------
@for $i from 0 through 20 {
  $num: $i * 5;

  @if $i == 0 {
    ._mt#{$num} { margin-top:    0 !important; }
    ._mb#{$num} { margin-bottom: 0 !important; }
    ._mr#{$num} { margin-right:  0 !important; }
    ._ml#{$num} { margin-left:   0 !important; }

    // 上下方向のマージン調整
    ._mv#{$num} {
      @extend ._mt#{$num};
      @extend ._mb#{$num};
    }

    // 水平方向のマージン調整
    ._mh#{$num} {
      @extend ._mr#{$num};
      @extend ._ml#{$num};
    }
  } @else {
    ._mt#{$num} { margin-top:    5px * $i !important; }
    ._mb#{$num} { margin-bottom: 5px * $i !important; }
    ._mr#{$num} { margin-right:  5px * $i !important; }
    ._ml#{$num} { margin-left:   5px * $i !important; }

    // 上下方向のマージン調整
    ._mv#{$num} {
      @extend ._mt#{$num};
      @extend ._mb#{$num};
    }

    // 水平方向のマージン調整
    ._mh#{$num} {
      @extend ._mr#{$num};
      @extend ._ml#{$num};
    }
  }
}