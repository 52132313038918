@charset "UTF-8";

// padding 調整用ユーティリティクラス
// -----------------------------------------------------------
@for $i from 0 through 10 {
  $num: $i * 5;

  @if $i == 0 {
    ._pt#{$num} { paddgin-top:    0 !important; }
    ._pb#{$num} { padding-bottom: 0 !important; }
    ._pr#{$num} { padding-right:  0 !important; }
    ._pl#{$num} { padding-left:   0 !important; }

    // 上下方向のマージン調整
    ._pv#{$num} {
      @extend ._pt#{$num};
      @extend ._pb#{$num};
    }

    // 水平方向のパディング調整
    ._ph#{$num} {
      @extend ._pr#{$num};
      @extend ._pl#{$num};
    }
  } @else {
    ._pt#{$num} { padding-top:    5px * $i !important; }
    ._pb#{$num} { padding-bottom: 5px * $i !important; }
    ._pr#{$num} { padding-right:  5px * $i !important; }
    ._pl#{$num} { padding-left:   5px * $i !important; }

    // 上下方向のマージン調整
    ._pv#{$num} {
      @extend ._pt#{$num};
      @extend ._pb#{$num};
    }

    // 水平方向のパディング調整
    ._ph#{$num} {
      @extend ._pr#{$num};
      @extend ._pl#{$num};
    }
  }
}