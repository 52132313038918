.block-products {
  margin: 30px 0 0;
  display: flex;

  @include below($sm) {
    margin: 15px 0 0;
    flex-direction: column;
  }

  > .cont {
    order: 2;
    flex-grow: 1;
    margin-left: 50px;

    @include below($sm) {
      order: 1;
      margin: 0;
    }

    &.-nobanner {
      margin-left: 0;
    }

    > *:first-child {
      margin-top: 0;
    }

    > p {
      font-size: 2.2rem;
      line-height: 1.5;

      @include below($sm) {
        font-size: 1.4rem;
      }

      em {
        font-size: 3.6rem;
        font-weight: bold;

        @include below($sm) {
          font-size: 1.8rem;
        }
      }
    }
  }

  > .banner {
    order: 1;
    width: 414px;
    flex-shrink: 0;

    @include below($sm) {
      order: 2;
      margin: 20px 0 0;
      width: auto;
    }

    > .etc {
      // text-align: right;
      margin: 0;
      font-size: 1.8rem;

      @include below($sm) {
        font-size: 1.4rem;
      }
    }

    > .list {
      display: flex;
      flex-wrap: wrap;

      @include below($sm) {
        // display: block;
      }

      > li {
        width: calc((100% - 15px * 2) / 2);
        margin: 0 15px 20px 0;
        display: flex;
        justify-content: center;
        align-items: center;

        @include below($sm) {
          // width: auto;
          // justify-content: flex-start;
          margin: 0 15px 15px 0;
        }

        &.-text {
          justify-content: flex-start;
          font-size: 1.8rem;

          @include below($sm) {
            font-size: 1.4rem;
          }
        }

        a {
          transition: opacity 0.2s $ease-out-quint;

          &:hover,
          &:active,
          &:focus {
            opacity: 0.8;
          }
        }
      }
    }
  }
}