.nav-pagination {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 80px 0 40px;
  font-size: 1.6rem;

  @include below($sm) {
    margin: 45px 0 25px;
    flex-direction: column;
    font-size: 1.4rem;
  }

  > .position {
    margin: 0 1em 0 0;

    @include below($sm) {
      margin: 0 0 1em;
    }
  }

  > .nav {
    display: flex;

    > li {
      margin: 0 3px;

      > .-current {
        display: block;
        padding: 2px 11px;
        // color: #9a928e;
        box-sizing: border-box;
        border: 1px solid palette('corp');
      }

      > a {
        display: block;
        color: palette('corp');
        padding: 3px 12px;
        text-decoration: none;
        transition: all 0.15s $ease-out-quint;

        &:hover,
        &:active,
        &:focus {
          background: palette('corp');
          color: #fff;
        }

        &.-prev,
        &.-next {
          background: palette('corp');
          color: #fff;
        }
      }
    }
  }
}