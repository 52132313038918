.l-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px 15px 15px 20px;

  @include below($sm) {
    margin: 0 0 -1px;
    padding: 11px 10px 12px 10px;
    overflow: hidden;
  }

  #top & {
    @include above($sm) {
      display: none;
    }
  }

  .header-right {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
  }
}