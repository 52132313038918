.top-division {
  border-width: 0 0 1px;
  border-style: solid;
  display: flex;

  @include below($sm) {
    display: block;
    border-width: 1px 0 0;
  }

  > .cont {
    width: 45%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    box-sizing: border-box;
    padding: 35px 50px 50px;

    @include below($sm) {
      width: auto;
      padding: 0 15px 35px;
      display: block;
    }

    > .txt {
      > .title {
        margin: 0;
        padding: 0 0 10px 80px;
        font-weight: normal;
        border-width: 0 0 1px;
        border-style: solid;
        position: relative;

        @include below($sm) {
          display: none;
        }

        &::before {
          content: '';
          display: block;
          position: absolute;
          top: 0.3em;
          left: 0;
        }

        > .ja {
          display: block;
          font-size: 3.6rem;
        }

        > .en {
          display: block;
          font-size: 1.8rem;
          letter-spacing: 0.3em;
          position: relative;
          left: 7px;
        }
      }
      > p {
        margin: 20px 0 0;
        font-size: 1.8rem;

        @include below($sm) {
          font-size: 1.4rem;
        }
      }
    }

    > .button {
      @include below($sm) {
        margin: 25px 0 0;
      }
      > ul {
        display: flex;

        @include below($sm) {
          display: block;
        }

        > li {
          width: 300px;
          margin: 0 20px 0 0;
          display: flex;

          @include below($sm) {
            margin: 0 auto 15px;
            width: 250px;
          }

          > a {
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 15px 25px;
            color: #fff;
            border-width: 1px;
            border-style: solid;
            text-decoration: none;
            text-align: center;
            position: relative;
            transition: all 0.15s $ease-out-quint;
            width: 100%;

            &::before {
              content: '';
              display: block;
              @include icon-ion('f3d1');
              position: absolute;
              top: 50%;
              right: 10px;
              transform: translateY(-50%);
              color: #fff;
              font-size: 2.4rem;

              @include below($sm) {
                font-size: 2rem;
              }
            }

            &[target="_blank"] {
              &::before {
                @include icon-fa('f08e');
                font-size: 1.8rem;
                right: 7px;

                @include below($sm) {
                  font-size: 1.6rem;
                }
              }
            }
          }
        }
      }
    }
  }

  > .media {
    width: 55%;

    @include above($sm) {
      transform: translateX(0);
      opacity: 1;
      transition-property: opacity, transform;
      transition-duration: 1.3s;
      transition-timing-function: $ease-in-out-quart;
      transition-delay: 0.2s;
    }

    @include below($sm) {
      display: none;
    }

    > .img {
      display: block;
      height: 100%;
      background-position: 0 0;
      background-repeat: no-repeat;
      background-size: cover;
      // background-size: 100% auto;
      min-height: 450px;
    }
  }

  &.-autoparts {
    border-color: palette('autoparts');
    background: url('../img/top-division_plaid_autoparts.gif') 0 0 repeat;
    .title {
      color: palette('autoparts');
      border-color: palette('autoparts');
      &::before {
        @include retina-sprite($icon-top-autoparts-group);
      }
    }
    .button {
      a {
        border-color: palette('autoparts');
        background: palette('autoparts');
        &:hover,
        &:active,
        &:focus {
          background: #fff;
          color: palette('autoparts');
          &::before {
            color: palette('autoparts');
          }
        }
      }
    }
  }
  &.-industrial {
    border-color: palette('industrial');
    background: url('../img/top-division_plaid_industrial.gif') 0 0 repeat;
    .title {
      color: palette('industrial');
      border-color: palette('industrial');
      &::before {
        @include retina-sprite($icon-top-industrial-group);
      }
    }
    .button {
      a {
        border-color: palette('industrial');
        background: palette('industrial');
        &:hover,
        &:active,
        &:focus {
          background: #fff;
          color: palette('industrial');
          &::before {
            color: palette('industrial');
          }
        }
      }
    }
  }
  &.-cellular {
    border-color: palette('cellular');
    background: url('../img/top-division_plaid_cellular.gif') 0 0 repeat;
    .title {
      color: palette('cellular');
      border-color: palette('cellular');
      &::before {
        @include retina-sprite($icon-top-cellular-group);
      }
    }
    .button {
      a {
        border-color: palette('cellular');
        background: palette('cellular');
        &:hover,
        &:active,
        &:focus {
          background: #fff;
          color: palette('cellular');
          &::before {
            color: palette('cellular');
          }
        }
      }
    }
  }
  &.-importcar {
    border-color: palette('importcar');
    background: url('../img/top-division_plaid_importcar.gif') 0 0 repeat;
    .title {
      color: palette('importcar');
      border-color: palette('importcar');
      &::before {
        @include retina-sprite($icon-top-importcar-group);
      }
    }
    .button {
      > ul {
        @include inbetween($sm, 1300px) {
          display: block;
        }
      }
      li {
        padding-top: 90px;
        @include below($sm) {
          padding-top: 0;
          background: none;
        }
        &.-alfa {
          // background: url('/img/index_importcar_banner_01.png') 50% 0 no-repeat; // 8/25 以降はこちらを有効化
          background: url('/img/index_importcar_banner_04.png') 50% 0 no-repeat;
        }
        &.-fiat {
          background: url('/img/index_importcar_banner_03.png') 50% 0 no-repeat;
        }
        &.-jeep {
          background: url('/img/index_importcar_banner_02.png') 50% 10px no-repeat;
        }
        @include inbetween($sm, 1300px) {
          background: none !important;
          padding-top: 15px;
        }
      }
      a {
        line-height: 1.4;
        padding: 8px 20px !important;
        box-sizing: border-box;
        min-height: 60px;
        &.-alfa {
          border-color: #992239;
          background: #992239;
          @include below($sm) {
            text-align: left;
          }
          &:hover,
          &:active,
          &:focus {
            background: #fff;
            color: #992239;
            &::before {
              color: #992239;
            }
          }
        }
        &.-fiat {
          border-color: #fd5a49;
          background: #fd5a49;
          @include below($sm) {
            text-align: left;
          }
          &:hover,
          &:active,
          &:focus {
            background: #fff;
            color: #fd5a49;
            &::before {
              color: #fd5a49;
            }
          }
        }
        &.-jeep {
          border-color: #383839;
          background: #383839;
          &:hover,
          &:active,
          &:focus {
            background: #fff;
            color: #383839;
            &::before {
              color: #383839;
            }
          }
        }
      }
    }
  }

  // アニメーション
  &.-before-scroll {
    @include above($sm) {
      > .media {
        transform: translateX(100%);
        opacity: 0;
      }
    }
  }
}