.block-wysiwyg {
  h1 {
    @extend .hdg-lv2;
  }

  h2 {
    @extend .hdg-lv3;
  }

  h3 {
    @extend .hdg-lv4;
  }

  h4 {
    @extend .hdg-lv5;
  }

  ul {
    @extend .list-bullet;
  }

  ol {
    @extend .list-ordered;
  }

  table {
    @extend .table-simple;
  }

  img[height] {
    height: auto !important;
  }

  p {
    clear: both;
  }

  em {
    font-weight: normal;
    font-style: italic;
  }

  a {
    &[target="_blank"]:not([href$=".pdf"]):not([href$=".doc"]):not([href$=".docx"]):not([href$=".xls"]):not([href$=".xlsx"]):not([href$=".ppt"]):not([href$=".pptx"]) {
      &::after {
        content: '';
        display: inline-block;
        @include icon-fa('f08e');
        margin-left: 6px;
      }
    }

    &[href$=".pdf"] {
      &::after {
        content: '';
        display: inline-block;
        @include icon-fa('f1c1');
        margin-left: 6px;
        color: #f10f10;
      }
    }

    &[href$=".doc"],
    &[href$=".docx"] {
      &::after {
        content: '';
        display: inline-block;
        @include icon-fa('f1c2');
        margin-left: 6px;
        color: #2472ba;
      }
    }

    &[href$=".xls"],
    &[href$=".xlsx"] {
      &::after {
        content: '';
        display: inline-block;
        @include icon-fa('f1c3');
        margin-left: 6px;
        color: #08743b;
      }
    }

    &[href$=".ppt"],
    &[href$=".pptx"] {
      &::after {
        content: '';
        display: inline-block;
        @include icon-fa('f1c4');
        margin-left: 6px;
        color: #dd5b26;
      }
    }
  }
}