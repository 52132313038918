.mobile-phonelink {
  position: absolute;
  top: 0;
  right: 0;

  @include above($sm) {
    display: none;
  }

  > a {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: #323948;
    color: #fff;
    text-decoration: none;
    font-size: 1.1rem;
    padding: 5px 10px;
    box-sizing: border-box;

    &::before {
      content: '';
      display: block;
      @include retina-sprite($icon-tel-sp-group);
      margin-bottom: 8px;
    }
  }
}