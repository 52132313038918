.top-globalnav {
  background: #323948;

  @include below($sm) {
    display: none;
  }

  &.-fixed {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 10000;
  }

  > .inner {
    max-width: 1100px;
    width: 100%;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 15px 0;
    box-sizing: border-box;
    height: 95px;

    @include inbetween($sm, 1100px) {
      padding: 15px;
      height: auto;
    }

    > .corpinfo {
      color: #fff;
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      margin-left: 25px;

      > .address {
        margin: 0;
        font-size: 2rem;
      }

      > .number {
        margin: 5px 0 0;
        display: flex;

        > li {
          margin-left: 20px;
          font-size: 1.6rem;

          > span {
            color: #333;
            background: #fff;
            display: inline-block;
            padding: 0 5px;
            margin: 0 5px 0 0;
          }
        }
      }
    }

    > ul {
      display: flex;
      align-items: center;
      font-size: 1.6rem;

      > li {
        margin-right: 20px;
        padding-right: 20px;
        border-right: 1px solid #999;

        &:last-child {
          margin-right: 0;
          padding-right: 0;
          border-right: none;
        }

        &.-tel {
          margin-right: 30px;
          padding-right: 0;
          border-right: none;

          > a {
            display: block;
            background: #fff;
            color: #333;
            font-size: 1.4rem;
            padding: 10px 15px 10px 55px;
            line-height: 1.4;
            position: relative;

            &::before {
              content: '';
              display: block;
              @include retina-sprite($icon-top-phone-group);
              position: absolute;
              top: 50%;
              left: 13px;
              transform: translateY(-50%);
            }

            &:hover,
            &:active,
            &:focus {
              text-decoration: none;
              background: #dfdfdf;
            }
          }
        }

        > a {
          text-decoration: none;
          color: #fff;
          &:hover,
          &:active,
          &:focus {
            text-decoration: underline;
          }
        }
      }
    }
  }
}