.l-globalnav {
  border-top: 1px solid #aeb4c1;
  border-bottom: 1px solid #aeb4c1;

  @include below($sm) {
    display: none;
  }

  > ul {
    display: flex;

    > li {
      width: calc(100% / 4);
      position: relative;

      > a {
        display: block;
        text-align: center;
        font-size: 1.8rem;
        text-decoration: none;
        background: #eee;
        padding: 20px 5px;
        position: relative;
        transition: all 0.15s $ease-out-quint;

        &::after {
          content: '';
          display: block;
          width: 30px;
          height: 100%;
          background: #eee;
          position: absolute;
          top: 0;
          right: -15px;
          transform: skewX(-20deg);
          z-index: 100;
          border-right: 1px solid #aeb4c1;
          transition: all 0.15s $ease-out-quint;
        }

        &.-current,
        &:hover,
        &:active,
        &:focus {
          color: #fff;
          &::after {
          }
        }

        // カテゴリ別ホバー設定
        &.-autoparts {
          &.-current,
          &:hover,
          &:active,
          &:focus {
            background: palette('autoparts');
            &::after {
              background: palette('autoparts');
            }
          }
        }
        &.-industrial {
          &.-current,
          &:hover,
          &:active,
          &:focus {
            background: palette('industrial');
            &::after {
              background: palette('industrial');
            }
          }
        }
        &.-cellular {
          &:hover,
          &:active,
          &:focus {
            background: palette('cellular');
            &::after {
              background: palette('cellular');
            }
          }
        }
        &.-importcar {
          &:hover,
          &:active,
          &:focus {
            background: palette('importcar');
            &::after {
              background: palette('importcar');
            }
          }
        }
      }

      > .dropdown {
        position: absolute;
        z-index: 1000;
        width: 100%;
        top: calc(100%);
        left: 2px;
        background: rgba(#000, 0.7);
        box-sizing: border-box;
        border-width: 0 2px 2px;
        border-style: solid;
        border-color: #444;
        padding: 25px 25px 15px;
        visibility: hidden;
        opacity: 0;
        transition: opacity 0.5s $ease-out-quint;

        &.-active {
          visibility: visible;
          opacity: 1;
        }

        > ul {
          > li {
            margin: 0 0 10px;
            position: relative;
            padding-left: 1em;

            &::before {
              content: '';
              display: block;
              @include icon-ion('f3d1');
              position: absolute;
              top: -0.18em;
              left: 0;
              color: #fff;
              font-size: 2rem;
            }


            > a {
              text-decoration: none;
              color: #fff;

              &:hover,
              &:active,
              &:focus {
                text-decoration: underline;
              }
            }
          }
        }
      }
    }
  }
}