.footer-copyright {
  background: #3d568c;
  color: #fff;
  font-size: 1.4rem;
  text-align: center;
  padding: 40px 0;
  height: 110px;

  @include below($sm) {
    padding: 18px 15px;
    font-size: 1rem;
    height: auto;
  }

  > p {
    margin: 0;
  }
}