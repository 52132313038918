.block-parallel {
  display: flex;

  @include below($sm) {
    display: block;
  }

  > .cont {
    width: 50%;
    box-sizing: border-box;
    padding: 30px 50px 50px;
    min-height: 576px;

    @include below($sm) {
      width: auto;
      padding: 15px 15px 35px;
      min-height: 0;
    }

    > .title {
      text-align: center;
      font-weight: normal;
      color: palette('corp');
      position: relative;
      padding: 0 0 15px;
      margin: 25px 0 45px;

      @include below($sm) {
        display: none;
      }

      &::before {
        content: '';
        display: block;
        margin: 0 auto 10px;
      }

      &.-auto-service {
        &::before {
          @include retina-sprite($icon-auto-service-group);
        }
      }
      &.-auto-products {
        &::before {
          @include retina-sprite($icon-auto-products-group);
        }
      }
      &.-ind-maker {
        &::before {
          @include retina-sprite($icon-ind-maker-group);
        }
      }

      &::after {
        content: '';
        display: block;
        width: 100px;
        height: 1px;
        background: palette('corp');
        position: absolute;
        bottom: 0;
        left: 50%;
        transform: translateX(-50%);
      }

      > .ja {
        display: block;
        font-size: 3.6rem;
      }

      > .en {
        display: block;
        font-size: 1.8rem;
        letter-spacing: 0.3em;
        margin: 3px 0 0;
      }
    }

    > .imghdg {
      margin: -15px -15px 0;

      @include above($sm) {
        display: none;
      }
    }

    > .hdg {
      border-bottom: 1px solid #333;
      font-size: 2.4rem;
      padding: 0 0 5px;
      font-weight: normal;

      @include below($sm) {
        font-size: 1.8rem;
      }
    }

    > .banner {
      margin: 25px 0 0;
      display: flex;
      flex-wrap: wrap;

      > li {
        margin: 0 15px 15px 0;

        @include below($sm) {
          width: 40%;
        }
      }
    }

    > .nav-button {
      @include below($sm) {
        margin-top: 35px;
      }
    }
  }

  > .media {
    width: 50%;
    flex-shrink: 0;

    @include below($sm) {
      display: none;
    }

    > .img {
      display: block;
      height: 100%;
      background-position: 50% 0;
      background-repeat: no-repeat;
      background-size: cover;
    }
  }

  &.-imgright {
    > .cont {
      order: 1;
    }
    > .media {
      order: 2;
    }
  }

  &.-autoparts {
    border-bottom: 1px solid #2d6392;
    > .cont {
      background: #e6e8f1;
    }
    &.-imgright {
      > .media {
        > .img {
          background-position: 100% 100%;
        }
      }
    }
  }
  &.-industrial {
    border-bottom: 1px solid #55b84a;
    > .cont {
      background: #e5f4e3;
    }
  }
}