@charset "UTF-8";

// Ionicons 呼び出し時の共通プロパティを一括管理
// -----------------------------------------------------------
$ionicons-font-path: "/design/fonts" !default;
$ionicons-font-family: "Ionicons" !default;
$ionicons-version: "3.0.0-alpha.3" !default;

@font-face {
 font-family: "Ion-Icons";
 src: url("#{$ionicons-font-path}/ionicons.eot?v=#{$ionicons-version}");
 src: url("#{$ionicons-font-path}/ionicons.eot?v=#{$ionicons-version}#iefix") format("embedded-opentype"),
      url("#{$ionicons-font-path}/ionicons.woff2?v=#{$ionicons-version}") format("woff2"),
      url("#{$ionicons-font-path}/ionicons.woff?v=#{$ionicons-version}") format("woff"),
      url("#{$ionicons-font-path}/ionicons.ttf?v=#{$ionicons-version}") format("truetype"),
      url("#{$ionicons-font-path}/ionicons.svg?v=#{$ionicons-version}#Ionicons") format("svg");
 font-weight: normal;
 font-style: normal;
}

// 原則、プレースホルダを extend で使用する
%ion {
  font-family: 'Ion-Icons';
  font-weight: normal;
  font-style: normal;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

._ion {
  @extend %ion;
}