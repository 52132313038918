.list-information {
  margin: 45px 0 0;
  border-top: 1px solid #bbb;

  > li {
    display: flex;
    border-bottom: 1px solid #bbb;
    padding: 22px 0;

    @include below($sm) {
      display: block;
      padding: 16px 0;
    }

    > .date {
      width: 160px;
      flex-shrink: 0;

      @include below($sm) {
        display: block;
        width: auto;
      }
    }

    > .closed {
      background: #ff0404;
      color: #fff;
      white-space: nowrap;
      align-self: flex-start;
      font-size: 1.4rem;
      padding: 4px 15px;
      margin: 0 20px 0 0;

      @include below($sm) {
        display: inline-block;
        font-size: 1.2rem;
        margin: 5px 0 0;
        padding: 3px 10px;
      }
    }

    > .category {
      margin: 0 20px 0 0;
      width: 145px;
      flex-shrink: 0;
      align-self: flex-start;

      @include below($sm) {
        display: block;
        margin: 5px 0 0;
        width: auto;
      }

      em {
        font-weight: normal;
        font-size: 1.4rem;
        padding: 4px 15px;
        background: #000;
        color: #fff;
        white-space: nowrap;
        display: block;
        text-align: center;

        @include below($sm) {
          font-size: 1.2rem;
          padding: 3px 10px;
          display: inline-block;
        }
      }

      &.-autoparts {
        em {
          background: palette('autoparts');
        }
      }
      &.-industrial {
        em {
          background: palette('industrial');
        }
      }
      &.-cellular {
        em {
          background: palette('cellular');
        }
      }
      &.-news {
        em {
          background: palette('corp');
        }
      }
      &.-recruit {
        em {
          background: #fc7c67;
        }
      }
    }

    > .title {
      flex-grow: 1;

      @include below($sm) {
        display: block;
        margin: 8px 0 0;
      }

      a {
        &[target="_blank"] {
          &::after {
            content: '';
            display: inline-block;
            @include icon-fa('f08e');
            margin-left: 6px;
          }
        }
      }
    }
  }
}