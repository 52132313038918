.link-button2 {
  display: block;
  background: #fff;
  color: palette('text');
  border: 1px solid palette('corp');
  text-decoration: none;
  text-align: center;
  font-size: 1.6rem;
  padding: 15px 5px;
  transition: all 0.15s $ease-out-quint;
  position: relative;
  box-sizing: border-box;
  width: 100%;

  @include below($sm) {
    font-size: 1.4rem;
    padding: 15px 10px;
  }

  &::before {
    content: '';
    display: block;
    @include icon-ion('f3d1');
    position: absolute;
    top: 50%;
    right: 10px;
    transform: translateY(-50%);
    color: palette('corp');
    font-size: 2rem;

    @include below($sm) {
      // font-size: 2rem;
    }
  }

  &:hover,
  &:active,
  &:focus {
    background: palette('corp');
    color: #fff;
    &::before {
      color: #fff;
    }
  }

  // 戻るボタン
  &.-back {
    &::before {
      right: auto;
      left: 10px;
      @include icon-ion('f3cf');
    }
  }
}