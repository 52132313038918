.l-hamburger {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 10000;
  border: none;
  background: palette('corp');
  box-sizing: border-box;
  width: 54px;
  height: 54px;
  padding: 0 12px;
  margin: 0;
  cursor: pointer;
  display: none;

  @include below($sm) {
    display: block;
  }

  > .bar {
    display: block;
    height: 3px;
    background: #fff;
    margin: 0 0 6px;
    width: 100%;
    transition: all 0.3s cubic-bezier(1, 0, 0, 1);

    &:nth-child(3) {
      margin-bottom: 0;
    }
  }

  // ドロワー展開時
  &[aria-expanded="true"] {
    // position: fixed; // ドロワー展開時は閉じるボタンは右上で固定させる

    > .bar {
      &:nth-child(1) {
        transform: translate3d(0, 9px, 0) rotate(45deg);
      }
      &:nth-child(2) {
        opacity: 0;
      }
      &:nth-child(3) {
        transform: translate3d(0, -9px, 0) rotate(-45deg);
      }
    }
  }
}