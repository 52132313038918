@charset "UTF-8";

%protect-image {
  user-select: none;

  // 要素上でのドラッグを禁止
  -webkit-user-drag: none;
     -moz-user-drag: none;
          user-drag: none;

  // iOS, Android 5+ で長押しによる画像保存を禁止
  -webkit-touch-callout: none;
     -moz-touch-callout: none;
          touch-callout: none;
}

// 実際の利用では、他のコンポーネントから ._nosave に @extend するか、
// HTML 上で個別の img 要素に直接 ._nosave を記述する
._nosave {
  @extend %protect-image;
}

// %protect-image をいったんはさんだのは、
// クラスの意味を明確にするため（_nosave.scss だとわかりづらい）