.panel-foot {
  margin: 50px 170px 0;
  border: 5px solid palette('corp');
  background: #fff;
  padding: 45px;

  @include below($sm) {
    border-width: 2px;
    padding: 15px;
    margin: 35px 0 0;
  }

  > .lead {
    margin: 0;
    text-align: center;
    font-size: 2rem;

    @include below($sm) {
      font-size: 1.4rem;
      text-align: left;

      br {
        display: none;
      }
    }
  }

  > .note {
    text-align: center;

    @include below($sm) {
      text-align: left;
      font-size: 1.2rem;
    }
  }

  .list-panel + &,
  .block-products + & {
    margin-top: 100px;
    @include below($sm) {
      margin-top: 55px;
    }
  }
}