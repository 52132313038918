.list-bullet {
  @extend %mt;

  > li {
    margin: 5px 0 0;
    padding-left: 1.1em;
    position: relative;

    &::before {
      content: '';
      display: block;
      width: 7px;
      height: 7px;
      background: palette('corp');
      position: absolute;
      top: 0.66em;
      left: 2px;
    }
  }
}