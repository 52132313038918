.l-main {
  background: #f5f5f5;
  padding: 1px 0 100px;

  @include below($sm) {
    padding: 1px 0 45px;
    z-index: 1000;
    position: relative;
  }

  p {
    @extend .pgh-normal;
  }

  &.-top {
    padding: 0;
    background: #fff;

    @include below($sm) {
      padding-top: 0 !important;
    }
  }
}