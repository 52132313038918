.recruit-message {
  display: flex;
  justify-content: space-between;
  margin: 0 0 -65px;
  background: #e3e9fe;

  @include below($sm) {
    margin: 0 0 -65px;
    flex-direction: column;
    padding: 25px 15px;
  }

  > .cont {
    flex-grow: 1;
    padding: 40px 50px;
    box-sizing: border-box;
    width: calc(100% / 2);

    @include below($sm) {
      order: 2;
      padding: 25px 0 15px;
      width: auto;
    }

    > .hdg {
      font-size: 2.6rem;
      border-bottom: 1px solid #333;
      padding: 0 0 5px;
      margin: 0;
      font-weight: normal;
      color: palette('corp');

      @include below($sm) {
        font-size: 2rem;
      }
    }

    > .subhdg {
      color: palette('corp');
      font-weight: normal;
      font-size: 2.4rem;
      margin: 30px 0 0;
      padding: 0 0 0 10px;
      border-left: 3px solid palette('corp');

      @include below($sm) {
        font-size: 1.8rem;
        margin-top: 15px;
        margin-bottom: -15px;
      }
    }

    > p {
      margin: 25px 0 0;
      line-height: 1.9;
    }

    > .nav-button {
      margin-top: 35px;
    }
  }

  > .media {
    flex-shrink: 0;
    box-sizing: border-box;
    width: calc(100% / 2);

    @include below($sm) {
      order: 1;
      width: auto;
    }

    > .img {
      margin: 0;
      position: relative;

      img {
        width: 100%;
        height: auto;
      }

      > .caption {
        position: absolute;
        left: 0;
        bottom: 0;
        width: 100%;
        display: block;
        background: rgba(#000, 0.7);
        color: #fff;
        text-align: center;
        padding: 25px 10px;
        box-sizing: border-box;

        @include below($sm) {
          padding: 14px 10px;
          font-size: 1.4rem;
        }
      }
    }
  }
}