.top-mobilehdg {
  margin: 0;
  padding: 15px 5px 40px;
  background-color: #000;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-size: cover;

  @include above($sm) {
    display: none;
  }

  > .hdg {
    margin: 0;
    position: relative;
    font-weight: normal;
    text-align: center;
    color: #fff !important;

    &::before {
      content: '';
      display: block;
      // @include retina-sprite($icon-autoparts-white-group);
      margin: 0 auto;
      transform: scale(0.7);
    }

    &::after {
      content: '';
      display: block;
      width: 50px;
      height: 1px;
      background: #fff;
      position: absolute;
      bottom: -12px;
      left: 50%;
      transform: translateX(-50%);
    }

    > .ja {
      display: block;
      font-size: 2.4rem;
    }

    > .en {
      display: block;
      font-size: 1.2rem;
      letter-spacing: 0.3em;
      margin: 3px 0 0;
    }
  }

  &.-autoparts {
    background-image: url('../img/top-mobilehdg_bg_autoparts.jpg');
    .hdg::before { @include retina-sprite($icon-autoparts-white-group); }
  }
  &.-industrial {
    background-image: url('../img/top-mobilehdg_bg_industrial.jpg');
    .hdg::before { @include retina-sprite($icon-industrial-white-group); }
  }
  &.-cellular {
    background-image: url('../img/top-mobilehdg_bg_cellular.jpg');
    .hdg::before { @include retina-sprite($icon-cellular-white-group); }
  }
  &.-importcar {
    background-image: url('../img/top-mobilehdg_bg_importcar.jpg');
    .hdg::before { @include retina-sprite($icon-importcar-white-group); }
  }
  &.-recruit {
    background-image: url('../img/top-mobilehdg_bg_recruit.jpg');
    background-position: 50% 0;
    .hdg::before { @include retina-sprite($icon-recruit-white-group); }
  }
  &.-company {
    background-image: url('../img/top-mobilehdg_bg_company.jpg');
    .hdg::before { @include retina-sprite($icon-company-white-group); }
  }
}