.nav-staff {
  border: 5px solid palette('corp');
  background: #fff;
  margin: 100px 0 0;
  padding: 45px;

  @include below($sm) {
    border: none;
    padding: 0;
    margin: 50px -15px 0;
  }

  > ul {
    display: flex;
    flex-wrap: wrap;

    @include below($sm) {
      justify-content: space-between;
    }

    > li {
      width: calc((100% - 1px * 4 ) / 4);
      margin: 0 1px 1px 0;

      @include below($sm) {
        width: calc((100% - 1px * 1 ) / 2);
        margin: 0 0 1px;
      }

      > a {
        position: relative;
        display: block;
        color: #fff;
        text-decoration: none;

        &::after {
          content: '';
          display: block;
          @include icon-ion('f3d1');
          position: absolute;
          top: 50%;
          right: 10px;
          transform: translateY(-50%);
          color: #fff;
          font-size: 2.8rem;

          @include below($sm) {
            font-size: 2rem;
            right: 6px;
          }
        }

        &.-current,
        &:hover,
        &:active,
        &:focus {
          > .img {
            &::before {
              opacity: 0;
            }
          }
        }

        > .img {
          display: block;
          padding-top: 80%;
          position: relative;
          background-position: 50% 0%;
          background-size: cover;
          background-repeat: no-repeat;

          @include below($sm) {
            padding-top: 75%;
          }

          &::before {
            content: '';
            display: block;
            width: 100%;
            height: 100%;
            background: #000;
            position: absolute;
            top: 0;
            left: 0;
            opacity: 0.25;
            transition: opacity 0.15s $ease-out-quint;
          }
        }

        > p {
          position: absolute;
          bottom: 10px;
          text-align: center;
          width: 100%;
          line-height: 1.4;
          text-shadow: 0 0 3px rgba(#000, 0.5);

          @include below($sm) {
            bottom: 10px;
          }

          > span {
            display: block;

            &.position {
              font-size: 1.8rem;
              line-height: 1.3;

              @include below($sm) {
                font-size: 1.4rem;
              }
            }

            &.name {
              margin-top: 5px;
              font-size: 1.7rem;

              @include below($sm) {
                margin-top: 3px;
                font-size: 1.3rem;
              }
            }
          }
        }
      }
    }
  }
}