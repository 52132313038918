@charset "UTF-8";

// Font Awesome 呼び出し時の共通プロパティを一括管理
// -----------------------------------------------------------
$fa-font-path: "/design/fonts" !default;
$fa-version  : "4.7.0" !default;

@font-face {
  font-family: 'Font-Awesome-Icons';
  src: url('#{$fa-font-path}/fontawesome-webfont.eot?v=#{$fa-version}');
  src: url('#{$fa-font-path}/fontawesome-webfont.eot?#iefix&v=#{$fa-version}') format('embedded-opentype'),
       url('#{$fa-font-path}/fontawesome-webfont.woff2?v=#{$fa-version}') format('woff2'),
       url('#{$fa-font-path}/fontawesome-webfont.woff?v=#{$fa-version}') format('woff'),
       url('#{$fa-font-path}/fontawesome-webfont.ttf?v=#{$fa-version}') format('truetype'),
       url('#{$fa-font-path}/fontawesome-webfont.svg?v=#{$fa-version}#fontawesomeregular') format('svg');
//  src: url('#{$fa-font-path}/FontAwesome.otf') format('opentype'); // used when developing fonts
  font-weight: normal;
  font-style: normal;
}

// 原則、プレースホルダを extend で使用する
%fa {
  font-family: 'Font-Awesome-Icons';
  font-weight: normal;
  font-style: normal;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

._fa {
  @extend %fa;
}