.block-history {
  margin: -25px 0 -45px;

  @include below($sm) {
    margin: -25px 0 -35px;
  }

  > .block {
    display: flex;

    @include below($sm) {
      display: block;
      border-left: 2px solid palette('corp');
      padding-left: 24px;
    }

    &:last-child {
      > .cont {
        @include below($sm) {
          padding-bottom: 45px;
        }
      }
    }

    > .year {
      border-right: 3px solid palette('corp');
      box-sizing: border-box;
      width: 220px;
      padding-top: 27px;
      padding-bottom: 35px;
      position: relative;
      flex-shrink: 0;

      @include below($sm) {
        border-right: none;
        border-bottom: 1px solid palette('corp');
        width: auto;
        padding: 19px 0 8px;
      }

      &::before {
        content: '';
        display: block;
        width: 30px;
        height: 30px;
        border-radius: 50%;
        background: palette('corp');
        position: absolute;
        top: 30px;
        right: -16px;

        @include below($sm) {
          top: 25px;
          right: auto;
          left: -35px;
          width: 20px;
          height: 20px;
        }
      }

      > .hdg {
        margin: 0;
        font-size: 2.8rem;
        color: palette('corp');
        font-weight: normal;

        @include below($sm) {
          font-size: 2.2rem;
        }
      }

      > .sub {
        margin: 0;
        font-size: 1.8rem;

        @include below($sm) {
          font-size: 1.4rem;
        }
      }
    }

    > .cont {
      flex-grow: 1;
      margin-left: 95px;
      padding-top: 30px;
      padding-bottom: 25px;

      @include below($sm) {
        padding: 10px 0 15px;
        margin: 0;
      }

      p {
        margin: 0;
      }

      .photo {
        display: flex;
        margin: 18px 0 0;
        align-items: center;

        @include below($sm) {
          margin: 15px 0 0;
          flex-wrap: wrap;
        }

        > li {
          margin-right: 35px;

          @include below($sm) {
            margin: 0 0 15px;
          }

          &:last-child {
            margin-right: 0;
          }

          > .caption {
            display: block;
            text-align: center;
            margin: 7px 0 0;
          }
        }

        &.-col2 {
          @include below($sm) {
            justify-content: space-between;
            > li {
              width: calc((100% - 15px * 1) / 2);
              text-align: center;
            }
          }
        }
      }
    }
  }
}