.nav-breadcrumb {
  margin: 13px 0 0 25px;

  @include below($sm) {
    margin: 10px 10px 30px;
  }

  > .path {
    margin: 0;
    font-size: 1.4rem;

    @include below($sm) {
      font-size: 1.2rem;
    }

    > .arrow {
      margin: 0 0.5em;
    }

    > .here {
      font-weight: normal;
    }
  }
}