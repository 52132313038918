@charset "UTF-8";

// ドキュメント
// -----------------------------------------------------------
html {
  margin: 0;
  font-size: 62.5%;
  line-height: 1.7;
  font-family: $sansSerifDefault;
  color: $baseFontColor;
  text-size-adjust: 100%;

  &.drawer-open {
    overflow: hidden;
  }
}

body {
  margin: 0;
  font-size: 1.6rem;
  background: #fff;
  @extend %wordwrap;

  @include below($sm) {
    font-size: 1.4rem;
    line-height: 1.7;
  }
}


// 見出し
// -----------------------------------------------------------
h1,
h2,
h3,
h4,
h5,
h6 {
  line-height: 1.4;
}


// リンク
// -----------------------------------------------------------
a {
  color: palette('corp');
  text-decoration: underline;

  &:hover,
  &:active,
  &:focus {
    text-decoration: none;
  }
}


// 画像
// -----------------------------------------------------------
img {
  vertical-align: bottom;
  max-width: 100%;
}


// コンテナ
// -----------------------------------------------------------
div, section, article, aside {
  box-sizing: border-box;
}


// 参照要素
// -----------------------------------------------------------
figure {
  margin: 0;
}


// 段落
// -----------------------------------------------------------
p {
  // @extend .pgh-normal;
  max-height: 100%;
}


// 強調
// -----------------------------------------------------------
strong {
  font-weight: bold;
}

em {
  font-weight: bold;
  font-style: normal;
}


// リスト
// -----------------------------------------------------------
ul,
ol,
li {
  margin: 0;
  padding: 0;
  list-style: none;
}

dl,
dt,
dd {
  margin: 0;
  padding: 0;
}


// 分割線
// -----------------------------------------------------------
hr {
  display: none;
}


// フォーム要素
// -----------------------------------------------------------
input,
textarea,
button,
optgroup,
select {
  font: inherit;
}


// フォーカス
// -----------------------------------------------------------
a, input, textarea, button {
  &:focus {
    // @extend %focus-outline;
  }
  &:hover,
  &:active {
    outline: none !important;
  }
}

// スムーススクロール後のキーボードフォーカス遷移のために
// 動的に tabindex を設定した要素に、アウトラインを設定しない
[tabindex] {
  outline: none !important;
}