.section-seco {
  margin: 65px auto;
  padding: 25px 0 45px;
  box-sizing: border-box;
  background: #fff;
  border-width: 1px 0;
  border-style: solid;
  border-color: #d6d6d6;

  @include below($sm) {
    padding: 25px 15px 35px;
  }

  &.-autoparts {
    margin: 0 auto;
    padding-bottom: 80px;
    background: url('../img/section-seco_bg_autoparts.gif') 0 0 repeat-x;
  }
  &.-industrial {
    margin: 0 auto;
    padding-bottom: 80px;
    background: url('../img/section-seco_bg_industrial.gif') 0 0 repeat-x;
  }

  > .inner {
    max-width: 1100px;
    width: 100%;
    margin: 0 auto;
  }
}