.header-rightaddress {
  color: palette('corp');
  margin: 0 0 12px;
  line-height: 1.4;
  display: flex;
  flex-direction: column;
  align-items: flex-end;

  @include below($sm) {
    display: none;
  }

  > .address {
    margin: 0;
    font-size: 2rem;
  }

  > .number {
    margin: 5px 0 0;
    display: flex;
    font-size: 1.6rem;

    li {
      margin-left: 20px;
    }

    span {
      font-size: 1.6rem;
      color: #fff;
      background: palette('corp');
      padding: 1px 10px;
      display: inline-block;
      margin: 0 5px 0 0;
    }
  }
}