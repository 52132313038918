.form-button {
  margin: 65px 0 0;
  display: flex;
  justify-content: center;

  @include below($sm) {
    margin: 45px 0 0;
    flex-direction: column;
  }

  > li {
    margin: 0 15px;

    @include below($sm) {
      margin: 6px 0;
      width: 100%;
    }

    > .button {
      display: block;
      background: palette('corp');
      color: #fff;
      text-decoration: none;
      text-align: center;
      font-size: 1.8rem;
      padding: 15px 85px;
      transition: background 0.15s $ease-out-quint;
      position: relative;
      box-sizing: border-box;
      width: 100%;
      border: none;
      cursor: pointer;

      @include below($sm) {
        font-size: 1.4rem;
        padding: 15px 10px;
      }

      &::before {
        content: '';
        display: block;
        @include icon-ion('f3d1');
        position: absolute;
        top: 50%;
        right: 10px;
        transform: translateY(-50%);
        color: #fff;
        font-size: 2.8rem;

        @include below($sm) {
          font-size: 2rem;
        }
      }

      &:hover,
      &:active,
      &:focus {
        background: palette('corp', 'light');
      }

      // 戻るボタン
      &.-back {
        &::before {
          right: auto;
          left: 10px;
          @include icon-ion('f3cf');
        }
      }
    }
  }
}