@charset "UTF-8";

// text-align 調整用ユーティリティクラス
// -----------------------------------------------------------
@each $position in left, center, right {
  ._#{$position} {
    text-align: $position !important;
  }
}

// vertical-align 調整用ユーティリティクラス
// -----------------------------------------------------------
@each $position in top, middle, bottom {
  ._#{$position} {
    vertical-align: $position !important;
  }
}