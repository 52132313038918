@charset "UTF-8";

// Font Awesome アイコン出力
// -----------------------------------------------------------
//    $unicode: Font Awesome に割り当てられた Unicode
//
//    使用例：
//    p.foo::before {
//        @include icon-fa('f0a9');
//    }
//
//    注意：
//    icon-fa の引数にはエスケープなしの文字コードを直接入力する。Font Awesome の変数を指定するとコンパイル時に文字化けしてしまう
// -----------------------------------------------------------
@mixin icon-fa($unicode) {
  content: unquote('\"') + unquote(str-insert($unicode, '\\', 1)) + unquote('\"'); // gulp-sass でコンパイルした時に文字コードが文字化けするのを回避
  @extend %fa;
}
