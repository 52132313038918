@charset "UTF-8";

// Google Open Sans の利用
// -----------------------------------------------------------
$useOpenSans: false !default;

$OpenSans: null !default;
@if $useOpenSans {
  $OpenSans: '"Open Sans",';
  @import url(https://fonts.googleapis.com/css?family=Open+Sans:400,700);
}


// Noto Sans Japanese の利用
// -----------------------------------------------------------
$useNotoSansJP: false !default;

$NotoSansJP: null !default;
@if $useNotoSansJP {
  $NotoSansJP: '"Noto Sans", "Noto Sans CJK JP", "Noto Sans Japanese",';
  @font-face {
    font-family: 'Noto Sans Japanese';
    font-style: normal;
    font-weight: 200;
    src: url('https://fonts.gstatic.com/ea/notosansjapanese/v6/NotoSansJP-Light.woff2') format('woff2'),
         url('https://fonts.gstatic.com/ea/notosansjapanese/v6/NotoSansJP-Light.woff') format('woff'),
         url('https://fonts.gstatic.com/ea/notosansjapanese/v6/NotoSansJP-Light.otf') format('opentype');
  }
  @font-face {
    font-family: 'Noto Sans Japanese';
    font-style: normal;
    font-weight: 400;
    src: local('NotoSansJP-Regular.otf'),
         url('https://fonts.gstatic.com/ea/notosansjapanese/v6/NotoSansJP-Regular.woff2') format('woff2'),
         url('https://fonts.gstatic.com/ea/notosansjapanese/v6/NotoSansJP-Regular.woff') format('woff'),
         url('https://fonts.gstatic.com/ea/notosansjapanese/v6/NotoSansJP-Regular.otf') format('opentype');
  }
  @font-face {
    font-family: 'Noto Sans Japanese';
    font-style: normal;
    font-weight: 700;
    src: url('https://fonts.gstatic.com/ea/notosansjapanese/v6/NotoSansJP-Bold.woff2') format('woff2'),
         url('https://fonts.gstatic.com/ea/notosansjapanese/v6/NotoSansJP-Bold.woff') format('woff'),
         url('https://fonts.gstatic.com/ea/notosansjapanese/v6/NotoSansJP-Bold.otf') format('opentype');
  }
}


// 游ゴシックのフォントウェイトを Win/Mac で出来るだけ揃える
// -----------------------------------------------------------
@font-face {
  font-family: 'YuGothicM';
  font-style: normal;
  font-weight: normal;
  src: local('YuGothic-Medium'),
       local('Yu Gothic Medium'), // Chrome 対応
       local('YuGothic-Regular'); // Win8.1 対応（Medium がない場合）
}
@font-face {
  font-family: 'YuGothicM';
  font-style: normal;
  font-weight: bold;
  src: local('YuGothic-Bold'),
       local('Yu Gothic'); // Chrome 対応
}


// フォントファミリータイプ
// -----------------------------------------------------------
// 基本形
$sansSerifDefault:
  #{$OpenSans}
  #{$NotoSansJP}
  -apple-system,
  "BlinkMacSystemFont",
  "Hiragino Kaku Gothic ProN",
  "Arial",
  "Meiryo",
  "YuGothicM",
  "YuGothic",
  sans-serif !default; // Hiragino Sans はフォントウェイトが少し扱いづらいため実務利用はまだ先

// Mac 向け
$sansSerifMac:
  #{$OpenSans}
  #{$NotoSansJP}
  -apple-system,
  "BlinkMacSystemFont",
  "Lucida Grande",
  "Helvetica Neue",
  "Hiragino Kaku Gothic ProN",
  "Hiragino Sans",
  sans-serif !default;

// Windows 向け
$sansSerifWin:
  #{$OpenSans}
  #{$NotoSansJP}
  "YuGothicM",
  "Meiryo",
  sans-serif !default;

// 英字基本形
$sansSeifEn:
  #{$OpenSans}
  #{$NotoSansJP}
  -apple-system,
  "BlinkMacSystemFont",
  "Helvetica Neue",
  "Tahoma",
  "Arial",
  sans-serif !default;

$helvetica:
  "Helvetica Neue",
  "Helvetica",
  "Roboto",
  "Arial",
  sans-serif !default;

$lucidaGrande:
  "Lucida Grande",
  "Tahoma",
  "Verdana",
  "Arial",
  sans-serif !default;

$monospace:
  "Menlo",
  "Monaco",
  "Bitstream Vera Sans Mono",
  "Lucida Console",
  "Consolas",
  "Courier",
  monospace !default;


// 基本フォントサイズ
// -----------------------------------------------------------
$baseFontSize: 16px !default;
$spFontSize  : 14px !default;


// デザイン（PSD）上の行の高さ
// -----------------------------------------------------------
$designedLineHeight: 54px;


// 実際の行の高さ
// -----------------------------------------------------------
$baseLineHeight: round( $designedLineHeight / $baseFontSize * 10 ) / 10; // 小数点第二位で四捨五入
