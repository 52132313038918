.nav-button {
  margin: 65px 0 0;

  // @include below($sm) {
  //   margin: 50px -15px -45px;
  // }

  > .navs {
    display: flex;
    justify-content: center;
    // line-height: 1.4;

    @include below($sm) {
      flex-wrap: wrap;
    }

    > li {
      // display: flex;

      // > a {
      //   display: flex;
      //   justify-content: center;
      //   align-items: center;
      // }

      // br {
      //   @include above($sm) {
      //     display: none;
      //   }
      // }
    }

    // &.-col4 {
    //   > li {
    //     width: calc((100% - 1px * 3) / 4);

    //     @include below($sm) {
    //       width: calc((100% - 1px * 1) / 2);
    //       margin-top: 1px;
    //     }
    //   }
    // }

    &.-col1 {
      > li {
        min-width: 285px;

        @include below($sm) {
          min-width: 0;
          width: 100%;
        }
      }
    }

    &.-col2 {
      > li {
        min-width: 285px;
        margin: 0 10px;

        @include below($sm) {
          margin: 10px 0;
          min-width: 0;
          width: 100%;
        }
      }
    }
  }

  .panel-foot & {
    margin-top: 25px;
  }

  .list-information + & {
    @include below($sm) {
      margin-top: 35px;
    }
  }

  .nav-pagination + & {
    @include below($sm) {
      margin-top: 35px;
    }
  }
}