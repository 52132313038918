.block-sitemap {
  display: flex;
  justify-content: space-between;

  @include below($sm) {
    display: block;
  }

  a {
    &[target="_blank"] {
      &::after {
        content: '';
        display: inline-block;
        @include icon-fa('f08e');
        margin-left: 6px;
      }
    }
  }

  > .col {
    width: calc((100% - 40px * 1) / 2);

    @include below($sm) {
      width: auto;
    }

    > ul {
      > li {
        margin: 0 0 10px;
        > a {
          font-size: 1.8rem;
          // font-weight: bold;
        }

        > ul {
          margin: 5px 0 15px 18px;
          > li {
            position: relative;
            padding-left: 1em;
            &::before {
              content: '\30FB';
              display: block;
              position: absolute;
              top: 0;
              left: 0;
              color: palette('corp');
            }
            > a {
              font-size: 1.4rem;
            }
          }
        }
      }
    }
  }
}