.media-parallel {
  $gutter: 50px;

  margin: 50px 0 0;
  display: flex;
  justify-content: space-between;

  @include below($sm) {
    flex-direction: column;
  }

  > .cont {
    @include above($sm) {
      flex-grow: 1;
    }

    @include below($sm) {
      order: 2 !important;
      margin: 25px 0 0;
    }

    > :first-child {
      margin-top: 0 !important;
    }
  }

  > .media {
    @include above($sm) {
      flex-shrink: 0;
    }

    @include below($sm) {
      order: 1 !important;
      width: auto !important;
    }

    > :first-child {
      margin-top: 0;
    }
  }

  // 左右配置指定
  &.-imgleft {
    > .cont {
      order: 2;
      margin-left: $gutter;
      @include below($sm) {
        margin-left: 0;
      }
    }
    > .media {
      order: 1;
    }
  }

  &.-imgright {
    > .cont {
      order: 1;
      margin-right: $gutter;
      @include below($sm) {
        margin-right: 0;
      }
    }
    > .media {
      order: 2;
    }
  }

  .hdg-lv2 + & {
    margin-top: 30px;
  }
}