.form-table {
  margin: 45px 0 0;
  // border-collapse: collapse;
  border-spacing: 0;

  @include below($sm) {
    display: block;
    margin: 35px -15px 0;
    width: auto;
  }

  th,
  td,
  tbody,
  tr {
    @include below($sm) {
      display: block;
    }
  }

  th {
    padding: 20px 100px 20px 15px;
    text-align: left;
    font-weight: normal;
    font-size: 1.8rem;
    white-space: nowrap;
    width: 1%;
    position: relative;
    vertical-align: top;
    background: palette('corp', 'superlight');
    border-width: 1px 0 0;
    border-style: solid;
    border-color: #fff;

    @include below($sm) {
      display: flex;
      justify-content: space-between;
      width: auto;
      padding: 10px 15px;
      font-size: 1.4rem;
      border: none;
    }
  }

  td {
    padding: 20px 15px 20px 35px;
    font-size: 1.6rem;
    width: 100%;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    border-width: 1px 0 0;
    border-style: dotted;
    border-color: #777;
    box-sizing: border-box;
    max-width: 820px;

    @include below($sm) {
      width: auto;
      display: block;
      padding: 20px 15px;
      max-width: none;
    }
  }

  tr {
    &:last-child {
      td {
        border-bottom: 1px dotted #777;
      }
    }
  }

  label {
    display: inline-block;
    cursor: pointer;
  }

  .must {
    font-size: 1.2rem;
    color: #fff;
    background: #c00;
    display: inline-block;
    padding: 2px 10px 1px;
    border-radius: 3px;
    position: absolute;
    top: 20px;
    right: 15px;

    @include below($sm) {
      position: static;
    }
  }

  .cond {
    margin-left: 0.7em;

    @include below($sm) {
      display: block;
      margin: 5px 0 0;
      font-size: 1.4rem;
    }
  }

  .error {
    width: 100%;
    margin: 0 0 5px;
    color: #f40000;
  }

  input[type="text"],
  input[type="tel"],
  input[type="email"],
  textarea {
    border: 1px solid #aaa;
    box-sizing: border-box;
    padding: 0.4em 0.6em;
    font-size: inherit;
    border-radius: 3px;

    @include below($sm) {
      padding: 0.2em 0.4em;
    }
  }

  input[type="text"],
  input[type="email"] {
    width: 540px;

    @include below($sm) {
      width: 100%;
    }
  }

  input[type="tel"] {
    width: 5em;
  }

  textarea {
    width: 100%;
    height: 20em;
  }

  .select {
    border: 1px solid #aaa;
    border-radius: 3px;
    padding: 10px;
    box-sizing: border-box;
    width: 340px;
    position: relative;
    background: #fff;

    @include below($sm) {
      width: 100%;
      font-size: 1.4rem;
    }

    &::after {
      content: '';
      display: block;
      @include icon-ion('f280');
      position: absolute;
      top: 50%;
      right: 10px;
      transform: translateY(-50%);
      font-size: 2.4rem;
      pointer-events: none; // ポインターイベントが無効化され、背後のセレクトボックスのイベントバブリングが有効になる
    }

    > select {
      width: 100%;
      background: transparent;
      border: none;
      appearance: none;
      cursor: pointer;
      font-size: 16px;

      &::-ms-expand {
        display: none;
      }
    }
  }
}