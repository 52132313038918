.top-recruit {
  background: url('../img/top-recruit_bg.jpg') 50% 0 no-repeat;
  background-size: cover;
  padding: 30px 0 50px;
  color: #fff;
  border-bottom: 1px solid #000;

  @include below($sm) {
    background: #f5f5f5;
    color: #333;
    padding: 0 0 25px;
    border-width: 1px 0;
    border-style: solid;
    border-color: #000;
  }

  > .hdg {
    text-align: center;
    font-weight: normal;
    color: #fff;
    position: relative;
    padding: 0 0 15px;
    margin: 25px 0 45px;

    @include below($sm) {
      display: none;
    }

    &::before {
      content: '';
      display: block;
      @include retina-sprite($icon-top-recruit-group);
      margin: 0 auto 10px;
    }

    &::after {
      content: '';
      display: block;
      width: 100px;
      height: 1px;
      background: #fff;
      position: absolute;
      bottom: 0;
      left: 50%;
      transform: translateX(-50%);
    }

    > .ja {
      display: block;
      font-size: 4rem;
      letter-spacing: 0.08em;
    }

    > .en {
      display: block;
      font-size: 1.8rem;
      letter-spacing: 0.3em;
      margin: 3px 0 0;
    }
  }

  > p {
    margin: 0;
    text-align: center;
    font-size: 1.8rem;

    @include below($sm) {
      text-align: left;
      font-size: 1.4rem;
      padding: 0 15px 0;
    }
  }
}