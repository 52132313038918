@charset "UTF-8";

// スクリーンリーダーに配慮した非表示
// -----------------------------------------------------------
._hide {
    font-size: 0;
    height: 0;
    width: 0;
    visibility: 0;
    overflow: 0;
    position: absolute;
    top: -9999px;
    left: -9999px;
}