.index-office {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;

  @include below($sm) {
    display: block;
  }

  > .item {
    width: calc((100% - 40px * 1) / 2);
    display: flex;
    flex-direction: column;
    margin: 40px 0 0;
    background: #fff;
    border: 1px solid #ccc;

    @include below($sm) {
      width: auto;
      margin: 20px 0 0;
    }

    > .cont {
      order: 2;
      padding: 15px 20px 20px;
      position: relative;

      @include below($sm) {
        padding: 15px;
      }

      > .hdg {
        color: palette('corp');
        font-weight: normal;
        font-size: 2.6rem;
        border-bottom: 1px solid #333;
        padding: 0 0 5px;
        margin: 0;

        @include below($sm) {
          font-size: 2rem;
        }
      }

      > .gmap {
        position: absolute;
        top: 20px;
        right: 20px;
        margin: 0;

        @include below($sm) {
          position: static;
          margin: 10px 0 0;
          text-align: right;
        }

        a {
          &[target="_blank"] {
            &::after {
              content: '';
              display: inline-block;
              @include icon-fa('f08e');
              margin-left: 6px;
            }
          }
        }
      }

      > .info {
        margin: 10px 0 0;
        display: flex;
        flex-wrap: wrap;
        align-items: flex-start;

        > dt {
          width: 75px;
          background: palette('corp');
          text-align: center;
          color: #fff;
          margin: 10px 0 0;
          padding: 3px 5px;
          box-sizing: border-box;

          @include below($sm) {
            padding: 1px 5px;
          }
        }

        > dd {
          width: calc(100% - (75px + 15px));
          margin: 10px 0 0 15px;
          padding: 3px 0;

          @include below($sm) {
            padding: 1px 0;
          }
        }
      }
    }

    > .img {
      order: 1;
      margin: -1px -1px 0;
    }
  }
}