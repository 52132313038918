.nav-imgbutton {
  margin: 90px 0 0;
  display: flex;
  justify-content: space-between;

  @include below($sm) {
    margin: 45px 0 0;
    display: block;
  }

  > li {
    @include below($sm) {
      margin: 0 0 10px;
    }
  }

  a {
    transition: opacity 0.15s $ease-out-quint;

    &:hover,
    &:active,
    &:focus {
      opacity: 0.8;
    }
  }
}