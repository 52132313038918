.top-linkbutton {
  display: block;
  background: transparent;
  text-decoration: none;
  text-align: center;
  font-size: 1.8rem;
  padding: 15px 5px;
  transition: background 0.15s $ease-out-quint;
  position: relative;
  box-sizing: border-box;
  width: 100%;
  border: 1px solid palette('corp');
  color: palette('corp');

  @include below($sm) {
    border-color: palette('corp');
    color: palette('corp');
    font-size: 1.4rem;
  }

  &::before {
    content: '';
    display: block;
    @include icon-ion('f3d1');
    position: absolute;
    top: 50%;
    right: 10px;
    transform: translateY(-50%);
    color: palette('corp');
    font-size: 2.4rem;

    @include below($sm) {
      font-size: 2rem;
      color: palette('corp');
    }
  }

  &:hover,
  &:active,
  &:focus {
    background: palette('corp');
    color: #fff;
    &::before {
      color: #fff;
    }
  }

  &.-recruit {
    border-color: #fff;
    color: #fff;
    background: rgba(#242425, 0.4);
    @include below($sm) {
      background: transparent;
      border-color: palette('corp');
      color: palette('corp');
    }
    &::before {
      color: #fff;
      @include below($sm) {
        color: palette('corp');
      }
    }
    &:hover,
    &:active,
    &:focus {
      background-color: #fff;
      color: #333;
      &::before {
        color: #333;
      }
    }
  }

  &.-company {
    border-color: #fff;
    color: #fff;
    background: palette('corp');
    @include below($sm) {
      background: transparent;
      border-color: palette('corp');
      color: palette('corp');
    }
    &::before {
      color: #fff;
      @include below($sm) {
        color: palette('corp');
      }
    }
    &:hover,
    &:active,
    &:focus {
      background-color: #fff;
      color: palette('corp');
      &::before {
        color: palette('corp');
      }
    }
  }
}