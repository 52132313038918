.header-nav {
  display: flex;
  font-size: 1.4rem;
  align-items: center;

  @include below($sm) {
    display: none;
  }

  li {
    margin-left: 30px;

    &.-tel {
      > a {
        display: block;
        background: #323948;
        color: #fff;
        font-size: 1.5rem;
        padding: 10px 15px;

        &::before {
          content: '';
          display: inline-block;
          @include retina-sprite($icon-tel-group);
          vertical-align: middle;
          margin-right: 10px;
          position: relative;
          top: -3px;
        }

        &:hover,
        &:active,
        &:focus {
          text-decoration: none;
          background: #565656;
        }
      }
    }
  }

  a {
    color: inherit;
    text-decoration: none;

    &:hover,
    &:active,
    &:focus {
      text-decoration: underline;
    }
  }
}