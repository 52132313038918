// 電話番号一覧ページのモーダル表示時のレイアウトを調整

#phoneNumber {
  @include above($sm) {

    .l-header,
    .l-globalnav,
    .l-footer,
    .nav-breadcrumb,
    .hdg-page {
      display: none;
    }

    .l-main {
      padding: 1px 50px 35px;
    }

    ._js-tel {
      a {
        color: inherit;
        text-decoration: none;
        pointer-events: none;
      }
    }

  }
}