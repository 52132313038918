.panel-note {
  border: 5px solid palette('corp');
  margin: 35px 0 0;
  padding: 45px;
  background: #fff;

  @include below($sm) {
    border-width: 2px;
    padding: 15px;
  }

  > :first-child {
    margin-top: 0;
  }
}