.top-news {
  background: #f9f9f9;
  padding: 105px 0 50px;
  border-bottom: 1px solid #ccc;

  @include below($sm) {
    padding: 10px 0 25px;
    border-bottom: 0;
  }

  > .inner {
    max-width: 1100px;
    width: 100%;
    box-sizing: border-box;
    margin: 0 auto;

    @include below($sm) {
      padding: 0 15px;
    }

    > .hdg {
      text-align: center;
      font-weight: normal;
      color: palette('corp');
      position: relative;
      padding: 0 0 15px;
      margin: 25px 0 45px;

      @include below($sm) {
        margin: 0 0 30px;
        padding: 0 0 10px;
      }

      &::before {
        content: '';
        display: block;
        @include retina-sprite($icon-top-news-group);
        margin: 0 auto 10px;

        @include below($sm) {
          transform: scale(0.7);
          transform-origin: 50% 100%;
        }
      }

      &::after {
        content: '';
        display: block;
        width: 100px;
        height: 1px;
        background: palette('corp');
        position: absolute;
        bottom: 0;
        left: 50%;
        transform: translateX(-50%);

        @include below($sm) {
          width: 50px;
        }
      }

      > .ja {
        display: block;
        font-size: 4rem;
        letter-spacing: 0.08em;

        @include below($sm) {
          font-size: 2.4rem;
        }
      }

      > .en {
        display: block;
        font-size: 1.8rem;
        letter-spacing: 0.3em;
        margin: 3px 0 0;

        @include below($sm) {
          font-size: 1.2rem;
        }
      }
    }

    > .list-information {
      @include below($sm) {
        margin-top: 25px;
      }
    }

  }
}