@charset "UTF-8";

// font-size 調整用ユーティリティクラス
// -----------------------------------------------------------
// font-size: 10rem - 16rem
@for $i from 10 through 16 {
  ._fz#{$i} {
    font-size: $i * 0.1rem;
  }
}

// font-size: 18rem - 30rem（2rem step）
@for $j from 9 through 15 {
  $num: $j * 2;
  ._fz#{$num} {
    font-size: $num * 0.1rem;
  }
}