.staff-profile {
  margin: 45px 0 0;
  display: flex;

  @include below($sm) {
    flex-direction: column;
  }

  > .cont {
    order: 2;
    background: #fff;
    padding: 25px 30px 30px;
    flex-grow: 1;
    position: relative;

    @include below($sm) {
      padding: 15px;
      border-width: 0 1px 1px;
      border-style: solid;
      border-color: #ccc;
    }

    > .division {
      margin: 0;
      font-size: 2rem;
      font-weight: normal;
      color: palette('corp');

      @include below($sm) {
        font-size: 1.4rem;
      }
    }

    > .name {
      margin: 8px 0 0;
      font-size: 2.4rem;
      font-weight: normal;
      color: palette('corp');
      border-bottom: 1px solid #333;
      padding: 0 0 5px;
      position: relative;

      @include below($sm) {
        font-size: 2rem;
        margin: 3px 0 0;
      }

      > .since {
        font-size: 1.6rem;
        position: absolute;
        bottom: 5px;
        right: 0;

        @include below($sm) {
          font-size: 1.4rem;
        }
      }
    }

    > .lead {
      margin: 18px 0 0;
      font-size: 2rem;

      @include below($sm) {
        font-size: 1.5rem;
      }
    }

    > p {
      margin: 1em 0 0;
    }
  }

  > .img {
    order: 1;
    flex-shrink: 0;
    width: 550px;

    @include below($sm) {
      width: auto;
    }
  }
}