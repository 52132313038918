// SCSS variables are information about icon's compiled state, stored under its original file name
//
// .icon-home {
//   width: $icon-home-width;
// }
//
// The large array-like variables contain all information about a single icon
// $icon-home: x y offset_x offset_y width height total_width total_height image_path;
//
// At the bottom of this section, we provide information about the spritesheet itself
// $spritesheet: width height image $spritesheet-sprites;
$icon-auto-products-name: 'icon_auto_products';
$icon-auto-products-x: 0px;
$icon-auto-products-y: 194px;
$icon-auto-products-offset-x: 0px;
$icon-auto-products-offset-y: -194px;
$icon-auto-products-width: 56px;
$icon-auto-products-height: 50px;
$icon-auto-products-total-width: 322px;
$icon-auto-products-total-height: 277px;
$icon-auto-products-image: '/design/img/sprite.png';
$icon-auto-products: (0px, 194px, 0px, -194px, 56px, 50px, 322px, 277px, '/design/img/sprite.png', 'icon_auto_products', );
$icon-auto-service-name: 'icon_auto_service';
$icon-auto-service-x: 0px;
$icon-auto-service-y: 65px;
$icon-auto-service-offset-x: 0px;
$icon-auto-service-offset-y: -65px;
$icon-auto-service-width: 67px;
$icon-auto-service-height: 54px;
$icon-auto-service-total-width: 322px;
$icon-auto-service-total-height: 277px;
$icon-auto-service-image: '/design/img/sprite.png';
$icon-auto-service: (0px, 65px, 0px, -65px, 67px, 54px, 322px, 277px, '/design/img/sprite.png', 'icon_auto_service', );
$icon-autoparts-white-name: 'icon_autoparts_white';
$icon-autoparts-white-x: 212px;
$icon-autoparts-white-y: 0px;
$icon-autoparts-white-offset-x: -212px;
$icon-autoparts-white-offset-y: 0px;
$icon-autoparts-white-width: 55px;
$icon-autoparts-white-height: 55px;
$icon-autoparts-white-total-width: 322px;
$icon-autoparts-white-total-height: 277px;
$icon-autoparts-white-image: '/design/img/sprite.png';
$icon-autoparts-white: (212px, 0px, -212px, 0px, 55px, 55px, 322px, 277px, '/design/img/sprite.png', 'icon_autoparts_white', );
$icon-cellular-white-name: 'icon_cellular_white';
$icon-cellular-white-x: 121px;
$icon-cellular-white-y: 124px;
$icon-cellular-white-offset-x: -121px;
$icon-cellular-white-offset-y: -124px;
$icon-cellular-white-width: 51px;
$icon-cellular-white-height: 60px;
$icon-cellular-white-total-width: 322px;
$icon-cellular-white-total-height: 277px;
$icon-cellular-white-image: '/design/img/sprite.png';
$icon-cellular-white: (121px, 124px, -121px, -124px, 51px, 60px, 322px, 277px, '/design/img/sprite.png', 'icon_cellular_white', );
$icon-company-white-name: 'icon_company_white';
$icon-company-white-x: 272px;
$icon-company-white-y: 0px;
$icon-company-white-offset-x: -272px;
$icon-company-white-offset-y: 0px;
$icon-company-white-width: 50px;
$icon-company-white-height: 54px;
$icon-company-white-total-width: 322px;
$icon-company-white-total-height: 277px;
$icon-company-white-image: '/design/img/sprite.png';
$icon-company-white: (272px, 0px, -272px, 0px, 50px, 54px, 322px, 277px, '/design/img/sprite.png', 'icon_company_white', );
$icon-importcar-white-name: 'icon_importcar_white';
$icon-importcar-white-x: 75px;
$icon-importcar-white-y: 0px;
$icon-importcar-white-offset-x: -75px;
$icon-importcar-white-offset-y: 0px;
$icon-importcar-white-width: 67px;
$icon-importcar-white-height: 60px;
$icon-importcar-white-total-width: 322px;
$icon-importcar-white-total-height: 277px;
$icon-importcar-white-image: '/design/img/sprite.png';
$icon-importcar-white: (75px, 0px, -75px, 0px, 67px, 60px, 322px, 277px, '/design/img/sprite.png', 'icon_importcar_white', );
$icon-ind-maker-name: 'icon_ind_maker';
$icon-ind-maker-x: 61px;
$icon-ind-maker-y: 194px;
$icon-ind-maker-offset-x: -61px;
$icon-ind-maker-offset-y: -194px;
$icon-ind-maker-width: 55px;
$icon-ind-maker-height: 50px;
$icon-ind-maker-total-width: 322px;
$icon-ind-maker-total-height: 277px;
$icon-ind-maker-image: '/design/img/sprite.png';
$icon-ind-maker: (61px, 194px, -61px, -194px, 55px, 50px, 322px, 277px, '/design/img/sprite.png', 'icon_ind_maker', );
$icon-industrial-white-name: 'icon_industrial_white';
$icon-industrial-white-x: 72px;
$icon-industrial-white-y: 65px;
$icon-industrial-white-offset-x: -72px;
$icon-industrial-white-offset-y: -65px;
$icon-industrial-white-width: 63px;
$icon-industrial-white-height: 54px;
$icon-industrial-white-total-width: 322px;
$icon-industrial-white-total-height: 277px;
$icon-industrial-white-image: '/design/img/sprite.png';
$icon-industrial-white: (72px, 65px, -72px, -65px, 63px, 54px, 322px, 277px, '/design/img/sprite.png', 'icon_industrial_white', );
$icon-mgn-autoparts-name: 'icon_mgn_autoparts';
$icon-mgn-autoparts-x: 121px;
$icon-mgn-autoparts-y: 194px;
$icon-mgn-autoparts-offset-x: -121px;
$icon-mgn-autoparts-offset-y: -194px;
$icon-mgn-autoparts-width: 30px;
$icon-mgn-autoparts-height: 30px;
$icon-mgn-autoparts-total-width: 322px;
$icon-mgn-autoparts-total-height: 277px;
$icon-mgn-autoparts-image: '/design/img/sprite.png';
$icon-mgn-autoparts: (121px, 194px, -121px, -194px, 30px, 30px, 322px, 277px, '/design/img/sprite.png', 'icon_mgn_autoparts', );
$icon-mgn-cellular-name: 'icon_mgn_cellular';
$icon-mgn-cellular-x: 177px;
$icon-mgn-cellular-y: 124px;
$icon-mgn-cellular-offset-x: -177px;
$icon-mgn-cellular-offset-y: -124px;
$icon-mgn-cellular-width: 28px;
$icon-mgn-cellular-height: 33px;
$icon-mgn-cellular-total-width: 322px;
$icon-mgn-cellular-total-height: 277px;
$icon-mgn-cellular-image: '/design/img/sprite.png';
$icon-mgn-cellular: (177px, 124px, -177px, -124px, 28px, 33px, 322px, 277px, '/design/img/sprite.png', 'icon_mgn_cellular', );
$icon-mgn-company-name: 'icon_mgn_company';
$icon-mgn-company-x: 156px;
$icon-mgn-company-y: 194px;
$icon-mgn-company-offset-x: -156px;
$icon-mgn-company-offset-y: -194px;
$icon-mgn-company-width: 28px;
$icon-mgn-company-height: 30px;
$icon-mgn-company-total-width: 322px;
$icon-mgn-company-total-height: 277px;
$icon-mgn-company-image: '/design/img/sprite.png';
$icon-mgn-company: (156px, 194px, -156px, -194px, 28px, 30px, 322px, 277px, '/design/img/sprite.png', 'icon_mgn_company', );
$icon-mgn-importcar-name: 'icon_mgn_importcar';
$icon-mgn-importcar-x: 189px;
$icon-mgn-importcar-y: 194px;
$icon-mgn-importcar-offset-x: -189px;
$icon-mgn-importcar-offset-y: -194px;
$icon-mgn-importcar-width: 30px;
$icon-mgn-importcar-height: 27px;
$icon-mgn-importcar-total-width: 322px;
$icon-mgn-importcar-total-height: 277px;
$icon-mgn-importcar-image: '/design/img/sprite.png';
$icon-mgn-importcar: (189px, 194px, -189px, -194px, 30px, 27px, 322px, 277px, '/design/img/sprite.png', 'icon_mgn_importcar', );
$icon-mgn-industrial-name: 'icon_mgn_industrial';
$icon-mgn-industrial-x: 272px;
$icon-mgn-industrial-y: 161px;
$icon-mgn-industrial-offset-x: -272px;
$icon-mgn-industrial-offset-y: -161px;
$icon-mgn-industrial-width: 35px;
$icon-mgn-industrial-height: 30px;
$icon-mgn-industrial-total-width: 322px;
$icon-mgn-industrial-total-height: 277px;
$icon-mgn-industrial-image: '/design/img/sprite.png';
$icon-mgn-industrial: (272px, 161px, -272px, -161px, 35px, 30px, 322px, 277px, '/design/img/sprite.png', 'icon_mgn_industrial', );
$icon-mgn-recruit-name: 'icon_mgn_recruit';
$icon-mgn-recruit-x: 224px;
$icon-mgn-recruit-y: 194px;
$icon-mgn-recruit-offset-x: -224px;
$icon-mgn-recruit-offset-y: -194px;
$icon-mgn-recruit-width: 27px;
$icon-mgn-recruit-height: 27px;
$icon-mgn-recruit-total-width: 322px;
$icon-mgn-recruit-total-height: 277px;
$icon-mgn-recruit-image: '/design/img/sprite.png';
$icon-mgn-recruit: (224px, 194px, -224px, -194px, 27px, 27px, 322px, 277px, '/design/img/sprite.png', 'icon_mgn_recruit', );
$icon-recruit-white-name: 'icon_recruit_white';
$icon-recruit-white-x: 212px;
$icon-recruit-white-y: 60px;
$icon-recruit-white-offset-x: -212px;
$icon-recruit-white-offset-y: -60px;
$icon-recruit-white-width: 54px;
$icon-recruit-white-height: 54px;
$icon-recruit-white-total-width: 322px;
$icon-recruit-white-total-height: 277px;
$icon-recruit-white-image: '/design/img/sprite.png';
$icon-recruit-white: (212px, 60px, -212px, -60px, 54px, 54px, 322px, 277px, '/design/img/sprite.png', 'icon_recruit_white', );
$icon-schedule-white-name: 'icon_schedule_white';
$icon-schedule-white-x: 272px;
$icon-schedule-white-y: 114px;
$icon-schedule-white-offset-x: -272px;
$icon-schedule-white-offset-y: -114px;
$icon-schedule-white-width: 48px;
$icon-schedule-white-height: 42px;
$icon-schedule-white-total-width: 322px;
$icon-schedule-white-total-height: 277px;
$icon-schedule-white-image: '/design/img/sprite.png';
$icon-schedule-white: (272px, 114px, -272px, -114px, 48px, 42px, 322px, 277px, '/design/img/sprite.png', 'icon_schedule_white', );
$icon-tel-name: 'icon_tel';
$icon-tel-x: 272px;
$icon-tel-y: 196px;
$icon-tel-offset-x: -272px;
$icon-tel-offset-y: -196px;
$icon-tel-width: 31px;
$icon-tel-height: 31px;
$icon-tel-total-width: 322px;
$icon-tel-total-height: 277px;
$icon-tel-image: '/design/img/sprite.png';
$icon-tel: (272px, 196px, -272px, -196px, 31px, 31px, 322px, 277px, '/design/img/sprite.png', 'icon_tel', );
$icon-tel-sp-name: 'icon_tel_sp';
$icon-tel-sp-x: 177px;
$icon-tel-sp-y: 162px;
$icon-tel-sp-offset-x: -177px;
$icon-tel-sp-offset-y: -162px;
$icon-tel-sp-width: 22px;
$icon-tel-sp-height: 22px;
$icon-tel-sp-total-width: 322px;
$icon-tel-sp-total-height: 277px;
$icon-tel-sp-image: '/design/img/sprite.png';
$icon-tel-sp: (177px, 162px, -177px, -162px, 22px, 22px, 322px, 277px, '/design/img/sprite.png', 'icon_tel_sp', );
$icon-top-autoparts-name: 'icon_top_autoparts';
$icon-top-autoparts-x: 147px;
$icon-top-autoparts-y: 0px;
$icon-top-autoparts-offset-x: -147px;
$icon-top-autoparts-offset-y: 0px;
$icon-top-autoparts-width: 60px;
$icon-top-autoparts-height: 60px;
$icon-top-autoparts-total-width: 322px;
$icon-top-autoparts-total-height: 277px;
$icon-top-autoparts-image: '/design/img/sprite.png';
$icon-top-autoparts: (147px, 0px, -147px, 0px, 60px, 60px, 322px, 277px, '/design/img/sprite.png', 'icon_top_autoparts', );
$icon-top-cellular-name: 'icon_top_cellular';
$icon-top-cellular-x: 0px;
$icon-top-cellular-y: 124px;
$icon-top-cellular-offset-x: 0px;
$icon-top-cellular-offset-y: -124px;
$icon-top-cellular-width: 55px;
$icon-top-cellular-height: 65px;
$icon-top-cellular-total-width: 322px;
$icon-top-cellular-total-height: 277px;
$icon-top-cellular-image: '/design/img/sprite.png';
$icon-top-cellular: (0px, 124px, 0px, -124px, 55px, 65px, 322px, 277px, '/design/img/sprite.png', 'icon_top_cellular', );
$icon-top-company-name: 'icon_top_company';
$icon-top-company-x: 60px;
$icon-top-company-y: 124px;
$icon-top-company-offset-x: -60px;
$icon-top-company-offset-y: -124px;
$icon-top-company-width: 56px;
$icon-top-company-height: 60px;
$icon-top-company-total-width: 322px;
$icon-top-company-total-height: 277px;
$icon-top-company-image: '/design/img/sprite.png';
$icon-top-company: (60px, 124px, -60px, -124px, 56px, 60px, 322px, 277px, '/design/img/sprite.png', 'icon_top_company', );
$icon-top-importcar-name: 'icon_top_importcar';
$icon-top-importcar-x: 147px;
$icon-top-importcar-y: 65px;
$icon-top-importcar-offset-x: -147px;
$icon-top-importcar-offset-y: -65px;
$icon-top-importcar-width: 60px;
$icon-top-importcar-height: 54px;
$icon-top-importcar-total-width: 322px;
$icon-top-importcar-total-height: 277px;
$icon-top-importcar-image: '/design/img/sprite.png';
$icon-top-importcar: (147px, 65px, -147px, -65px, 60px, 54px, 322px, 277px, '/design/img/sprite.png', 'icon_top_importcar', );
$icon-top-industrial-name: 'icon_top_industrial';
$icon-top-industrial-x: 0px;
$icon-top-industrial-y: 0px;
$icon-top-industrial-offset-x: 0px;
$icon-top-industrial-offset-y: 0px;
$icon-top-industrial-width: 70px;
$icon-top-industrial-height: 60px;
$icon-top-industrial-total-width: 322px;
$icon-top-industrial-total-height: 277px;
$icon-top-industrial-image: '/design/img/sprite.png';
$icon-top-industrial: (0px, 0px, 0px, 0px, 70px, 60px, 322px, 277px, '/design/img/sprite.png', 'icon_top_industrial', );
$icon-top-news-name: 'icon_top_news';
$icon-top-news-x: 272px;
$icon-top-news-y: 59px;
$icon-top-news-offset-x: -272px;
$icon-top-news-offset-y: -59px;
$icon-top-news-width: 50px;
$icon-top-news-height: 50px;
$icon-top-news-total-width: 322px;
$icon-top-news-total-height: 277px;
$icon-top-news-image: '/design/img/sprite.png';
$icon-top-news: (272px, 59px, -272px, -59px, 50px, 50px, 322px, 277px, '/design/img/sprite.png', 'icon_top_news', );
$icon-top-phone-name: 'icon_top_phone';
$icon-top-phone-x: 0px;
$icon-top-phone-y: 249px;
$icon-top-phone-offset-x: 0px;
$icon-top-phone-offset-y: -249px;
$icon-top-phone-width: 28px;
$icon-top-phone-height: 28px;
$icon-top-phone-total-width: 322px;
$icon-top-phone-total-height: 277px;
$icon-top-phone-image: '/design/img/sprite.png';
$icon-top-phone: (0px, 249px, 0px, -249px, 28px, 28px, 322px, 277px, '/design/img/sprite.png', 'icon_top_phone', );
$icon-top-recruit-name: 'icon_top_recruit';
$icon-top-recruit-x: 212px;
$icon-top-recruit-y: 119px;
$icon-top-recruit-offset-x: -212px;
$icon-top-recruit-offset-y: -119px;
$icon-top-recruit-width: 54px;
$icon-top-recruit-height: 54px;
$icon-top-recruit-total-width: 322px;
$icon-top-recruit-total-height: 277px;
$icon-top-recruit-image: '/design/img/sprite.png';
$icon-top-recruit: (212px, 119px, -212px, -119px, 54px, 54px, 322px, 277px, '/design/img/sprite.png', 'icon_top_recruit', );
$icon-auto-products-2x-name: 'icon_auto_products@2x';
$icon-auto-products-2x-x: 0px;
$icon-auto-products-2x-y: 388px;
$icon-auto-products-2x-offset-x: 0px;
$icon-auto-products-2x-offset-y: -388px;
$icon-auto-products-2x-width: 112px;
$icon-auto-products-2x-height: 100px;
$icon-auto-products-2x-total-width: 644px;
$icon-auto-products-2x-total-height: 554px;
$icon-auto-products-2x-image: '/design/img/sprite@2x.png';
$icon-auto-products-2x: (0px, 388px, 0px, -388px, 112px, 100px, 644px, 554px, '/design/img/sprite@2x.png', 'icon_auto_products@2x', );
$icon-auto-service-2x-name: 'icon_auto_service@2x';
$icon-auto-service-2x-x: 0px;
$icon-auto-service-2x-y: 130px;
$icon-auto-service-2x-offset-x: 0px;
$icon-auto-service-2x-offset-y: -130px;
$icon-auto-service-2x-width: 134px;
$icon-auto-service-2x-height: 108px;
$icon-auto-service-2x-total-width: 644px;
$icon-auto-service-2x-total-height: 554px;
$icon-auto-service-2x-image: '/design/img/sprite@2x.png';
$icon-auto-service-2x: (0px, 130px, 0px, -130px, 134px, 108px, 644px, 554px, '/design/img/sprite@2x.png', 'icon_auto_service@2x', );
$icon-autoparts-white-2x-name: 'icon_autoparts_white@2x';
$icon-autoparts-white-2x-x: 424px;
$icon-autoparts-white-2x-y: 0px;
$icon-autoparts-white-2x-offset-x: -424px;
$icon-autoparts-white-2x-offset-y: 0px;
$icon-autoparts-white-2x-width: 110px;
$icon-autoparts-white-2x-height: 110px;
$icon-autoparts-white-2x-total-width: 644px;
$icon-autoparts-white-2x-total-height: 554px;
$icon-autoparts-white-2x-image: '/design/img/sprite@2x.png';
$icon-autoparts-white-2x: (424px, 0px, -424px, 0px, 110px, 110px, 644px, 554px, '/design/img/sprite@2x.png', 'icon_autoparts_white@2x', );
$icon-cellular-white-2x-name: 'icon_cellular_white@2x';
$icon-cellular-white-2x-x: 242px;
$icon-cellular-white-2x-y: 248px;
$icon-cellular-white-2x-offset-x: -242px;
$icon-cellular-white-2x-offset-y: -248px;
$icon-cellular-white-2x-width: 102px;
$icon-cellular-white-2x-height: 120px;
$icon-cellular-white-2x-total-width: 644px;
$icon-cellular-white-2x-total-height: 554px;
$icon-cellular-white-2x-image: '/design/img/sprite@2x.png';
$icon-cellular-white-2x: (242px, 248px, -242px, -248px, 102px, 120px, 644px, 554px, '/design/img/sprite@2x.png', 'icon_cellular_white@2x', );
$icon-company-white-2x-name: 'icon_company_white@2x';
$icon-company-white-2x-x: 544px;
$icon-company-white-2x-y: 0px;
$icon-company-white-2x-offset-x: -544px;
$icon-company-white-2x-offset-y: 0px;
$icon-company-white-2x-width: 100px;
$icon-company-white-2x-height: 108px;
$icon-company-white-2x-total-width: 644px;
$icon-company-white-2x-total-height: 554px;
$icon-company-white-2x-image: '/design/img/sprite@2x.png';
$icon-company-white-2x: (544px, 0px, -544px, 0px, 100px, 108px, 644px, 554px, '/design/img/sprite@2x.png', 'icon_company_white@2x', );
$icon-importcar-white-2x-name: 'icon_importcar_white@2x';
$icon-importcar-white-2x-x: 150px;
$icon-importcar-white-2x-y: 0px;
$icon-importcar-white-2x-offset-x: -150px;
$icon-importcar-white-2x-offset-y: 0px;
$icon-importcar-white-2x-width: 134px;
$icon-importcar-white-2x-height: 120px;
$icon-importcar-white-2x-total-width: 644px;
$icon-importcar-white-2x-total-height: 554px;
$icon-importcar-white-2x-image: '/design/img/sprite@2x.png';
$icon-importcar-white-2x: (150px, 0px, -150px, 0px, 134px, 120px, 644px, 554px, '/design/img/sprite@2x.png', 'icon_importcar_white@2x', );
$icon-ind-maker-2x-name: 'icon_ind_maker@2x';
$icon-ind-maker-2x-x: 122px;
$icon-ind-maker-2x-y: 388px;
$icon-ind-maker-2x-offset-x: -122px;
$icon-ind-maker-2x-offset-y: -388px;
$icon-ind-maker-2x-width: 110px;
$icon-ind-maker-2x-height: 100px;
$icon-ind-maker-2x-total-width: 644px;
$icon-ind-maker-2x-total-height: 554px;
$icon-ind-maker-2x-image: '/design/img/sprite@2x.png';
$icon-ind-maker-2x: (122px, 388px, -122px, -388px, 110px, 100px, 644px, 554px, '/design/img/sprite@2x.png', 'icon_ind_maker@2x', );
$icon-industrial-white-2x-name: 'icon_industrial_white@2x';
$icon-industrial-white-2x-x: 144px;
$icon-industrial-white-2x-y: 130px;
$icon-industrial-white-2x-offset-x: -144px;
$icon-industrial-white-2x-offset-y: -130px;
$icon-industrial-white-2x-width: 126px;
$icon-industrial-white-2x-height: 108px;
$icon-industrial-white-2x-total-width: 644px;
$icon-industrial-white-2x-total-height: 554px;
$icon-industrial-white-2x-image: '/design/img/sprite@2x.png';
$icon-industrial-white-2x: (144px, 130px, -144px, -130px, 126px, 108px, 644px, 554px, '/design/img/sprite@2x.png', 'icon_industrial_white@2x', );
$icon-mgn-autoparts-2x-name: 'icon_mgn_autoparts@2x';
$icon-mgn-autoparts-2x-x: 242px;
$icon-mgn-autoparts-2x-y: 388px;
$icon-mgn-autoparts-2x-offset-x: -242px;
$icon-mgn-autoparts-2x-offset-y: -388px;
$icon-mgn-autoparts-2x-width: 60px;
$icon-mgn-autoparts-2x-height: 60px;
$icon-mgn-autoparts-2x-total-width: 644px;
$icon-mgn-autoparts-2x-total-height: 554px;
$icon-mgn-autoparts-2x-image: '/design/img/sprite@2x.png';
$icon-mgn-autoparts-2x: (242px, 388px, -242px, -388px, 60px, 60px, 644px, 554px, '/design/img/sprite@2x.png', 'icon_mgn_autoparts@2x', );
$icon-mgn-cellular-2x-name: 'icon_mgn_cellular@2x';
$icon-mgn-cellular-2x-x: 354px;
$icon-mgn-cellular-2x-y: 248px;
$icon-mgn-cellular-2x-offset-x: -354px;
$icon-mgn-cellular-2x-offset-y: -248px;
$icon-mgn-cellular-2x-width: 56px;
$icon-mgn-cellular-2x-height: 66px;
$icon-mgn-cellular-2x-total-width: 644px;
$icon-mgn-cellular-2x-total-height: 554px;
$icon-mgn-cellular-2x-image: '/design/img/sprite@2x.png';
$icon-mgn-cellular-2x: (354px, 248px, -354px, -248px, 56px, 66px, 644px, 554px, '/design/img/sprite@2x.png', 'icon_mgn_cellular@2x', );
$icon-mgn-company-2x-name: 'icon_mgn_company@2x';
$icon-mgn-company-2x-x: 312px;
$icon-mgn-company-2x-y: 388px;
$icon-mgn-company-2x-offset-x: -312px;
$icon-mgn-company-2x-offset-y: -388px;
$icon-mgn-company-2x-width: 56px;
$icon-mgn-company-2x-height: 60px;
$icon-mgn-company-2x-total-width: 644px;
$icon-mgn-company-2x-total-height: 554px;
$icon-mgn-company-2x-image: '/design/img/sprite@2x.png';
$icon-mgn-company-2x: (312px, 388px, -312px, -388px, 56px, 60px, 644px, 554px, '/design/img/sprite@2x.png', 'icon_mgn_company@2x', );
$icon-mgn-importcar-2x-name: 'icon_mgn_importcar@2x';
$icon-mgn-importcar-2x-x: 378px;
$icon-mgn-importcar-2x-y: 388px;
$icon-mgn-importcar-2x-offset-x: -378px;
$icon-mgn-importcar-2x-offset-y: -388px;
$icon-mgn-importcar-2x-width: 60px;
$icon-mgn-importcar-2x-height: 54px;
$icon-mgn-importcar-2x-total-width: 644px;
$icon-mgn-importcar-2x-total-height: 554px;
$icon-mgn-importcar-2x-image: '/design/img/sprite@2x.png';
$icon-mgn-importcar-2x: (378px, 388px, -378px, -388px, 60px, 54px, 644px, 554px, '/design/img/sprite@2x.png', 'icon_mgn_importcar@2x', );
$icon-mgn-industrial-2x-name: 'icon_mgn_industrial@2x';
$icon-mgn-industrial-2x-x: 544px;
$icon-mgn-industrial-2x-y: 322px;
$icon-mgn-industrial-2x-offset-x: -544px;
$icon-mgn-industrial-2x-offset-y: -322px;
$icon-mgn-industrial-2x-width: 70px;
$icon-mgn-industrial-2x-height: 60px;
$icon-mgn-industrial-2x-total-width: 644px;
$icon-mgn-industrial-2x-total-height: 554px;
$icon-mgn-industrial-2x-image: '/design/img/sprite@2x.png';
$icon-mgn-industrial-2x: (544px, 322px, -544px, -322px, 70px, 60px, 644px, 554px, '/design/img/sprite@2x.png', 'icon_mgn_industrial@2x', );
$icon-mgn-recruit-2x-name: 'icon_mgn_recruit@2x';
$icon-mgn-recruit-2x-x: 448px;
$icon-mgn-recruit-2x-y: 388px;
$icon-mgn-recruit-2x-offset-x: -448px;
$icon-mgn-recruit-2x-offset-y: -388px;
$icon-mgn-recruit-2x-width: 54px;
$icon-mgn-recruit-2x-height: 54px;
$icon-mgn-recruit-2x-total-width: 644px;
$icon-mgn-recruit-2x-total-height: 554px;
$icon-mgn-recruit-2x-image: '/design/img/sprite@2x.png';
$icon-mgn-recruit-2x: (448px, 388px, -448px, -388px, 54px, 54px, 644px, 554px, '/design/img/sprite@2x.png', 'icon_mgn_recruit@2x', );
$icon-recruit-white-2x-name: 'icon_recruit_white@2x';
$icon-recruit-white-2x-x: 424px;
$icon-recruit-white-2x-y: 120px;
$icon-recruit-white-2x-offset-x: -424px;
$icon-recruit-white-2x-offset-y: -120px;
$icon-recruit-white-2x-width: 108px;
$icon-recruit-white-2x-height: 108px;
$icon-recruit-white-2x-total-width: 644px;
$icon-recruit-white-2x-total-height: 554px;
$icon-recruit-white-2x-image: '/design/img/sprite@2x.png';
$icon-recruit-white-2x: (424px, 120px, -424px, -120px, 108px, 108px, 644px, 554px, '/design/img/sprite@2x.png', 'icon_recruit_white@2x', );
$icon-schedule-white-2x-name: 'icon_schedule_white@2x';
$icon-schedule-white-2x-x: 544px;
$icon-schedule-white-2x-y: 228px;
$icon-schedule-white-2x-offset-x: -544px;
$icon-schedule-white-2x-offset-y: -228px;
$icon-schedule-white-2x-width: 96px;
$icon-schedule-white-2x-height: 84px;
$icon-schedule-white-2x-total-width: 644px;
$icon-schedule-white-2x-total-height: 554px;
$icon-schedule-white-2x-image: '/design/img/sprite@2x.png';
$icon-schedule-white-2x: (544px, 228px, -544px, -228px, 96px, 84px, 644px, 554px, '/design/img/sprite@2x.png', 'icon_schedule_white@2x', );
$icon-tel-2x-name: 'icon_tel@2x';
$icon-tel-2x-x: 544px;
$icon-tel-2x-y: 392px;
$icon-tel-2x-offset-x: -544px;
$icon-tel-2x-offset-y: -392px;
$icon-tel-2x-width: 62px;
$icon-tel-2x-height: 62px;
$icon-tel-2x-total-width: 644px;
$icon-tel-2x-total-height: 554px;
$icon-tel-2x-image: '/design/img/sprite@2x.png';
$icon-tel-2x: (544px, 392px, -544px, -392px, 62px, 62px, 644px, 554px, '/design/img/sprite@2x.png', 'icon_tel@2x', );
$icon-tel-sp-2x-name: 'icon_tel_sp@2x';
$icon-tel-sp-2x-x: 354px;
$icon-tel-sp-2x-y: 324px;
$icon-tel-sp-2x-offset-x: -354px;
$icon-tel-sp-2x-offset-y: -324px;
$icon-tel-sp-2x-width: 44px;
$icon-tel-sp-2x-height: 44px;
$icon-tel-sp-2x-total-width: 644px;
$icon-tel-sp-2x-total-height: 554px;
$icon-tel-sp-2x-image: '/design/img/sprite@2x.png';
$icon-tel-sp-2x: (354px, 324px, -354px, -324px, 44px, 44px, 644px, 554px, '/design/img/sprite@2x.png', 'icon_tel_sp@2x', );
$icon-top-autoparts-2x-name: 'icon_top_autoparts@2x';
$icon-top-autoparts-2x-x: 294px;
$icon-top-autoparts-2x-y: 0px;
$icon-top-autoparts-2x-offset-x: -294px;
$icon-top-autoparts-2x-offset-y: 0px;
$icon-top-autoparts-2x-width: 120px;
$icon-top-autoparts-2x-height: 120px;
$icon-top-autoparts-2x-total-width: 644px;
$icon-top-autoparts-2x-total-height: 554px;
$icon-top-autoparts-2x-image: '/design/img/sprite@2x.png';
$icon-top-autoparts-2x: (294px, 0px, -294px, 0px, 120px, 120px, 644px, 554px, '/design/img/sprite@2x.png', 'icon_top_autoparts@2x', );
$icon-top-cellular-2x-name: 'icon_top_cellular@2x';
$icon-top-cellular-2x-x: 0px;
$icon-top-cellular-2x-y: 248px;
$icon-top-cellular-2x-offset-x: 0px;
$icon-top-cellular-2x-offset-y: -248px;
$icon-top-cellular-2x-width: 110px;
$icon-top-cellular-2x-height: 130px;
$icon-top-cellular-2x-total-width: 644px;
$icon-top-cellular-2x-total-height: 554px;
$icon-top-cellular-2x-image: '/design/img/sprite@2x.png';
$icon-top-cellular-2x: (0px, 248px, 0px, -248px, 110px, 130px, 644px, 554px, '/design/img/sprite@2x.png', 'icon_top_cellular@2x', );
$icon-top-company-2x-name: 'icon_top_company@2x';
$icon-top-company-2x-x: 120px;
$icon-top-company-2x-y: 248px;
$icon-top-company-2x-offset-x: -120px;
$icon-top-company-2x-offset-y: -248px;
$icon-top-company-2x-width: 112px;
$icon-top-company-2x-height: 120px;
$icon-top-company-2x-total-width: 644px;
$icon-top-company-2x-total-height: 554px;
$icon-top-company-2x-image: '/design/img/sprite@2x.png';
$icon-top-company-2x: (120px, 248px, -120px, -248px, 112px, 120px, 644px, 554px, '/design/img/sprite@2x.png', 'icon_top_company@2x', );
$icon-top-importcar-2x-name: 'icon_top_importcar@2x';
$icon-top-importcar-2x-x: 294px;
$icon-top-importcar-2x-y: 130px;
$icon-top-importcar-2x-offset-x: -294px;
$icon-top-importcar-2x-offset-y: -130px;
$icon-top-importcar-2x-width: 120px;
$icon-top-importcar-2x-height: 108px;
$icon-top-importcar-2x-total-width: 644px;
$icon-top-importcar-2x-total-height: 554px;
$icon-top-importcar-2x-image: '/design/img/sprite@2x.png';
$icon-top-importcar-2x: (294px, 130px, -294px, -130px, 120px, 108px, 644px, 554px, '/design/img/sprite@2x.png', 'icon_top_importcar@2x', );
$icon-top-industrial-2x-name: 'icon_top_industrial@2x';
$icon-top-industrial-2x-x: 0px;
$icon-top-industrial-2x-y: 0px;
$icon-top-industrial-2x-offset-x: 0px;
$icon-top-industrial-2x-offset-y: 0px;
$icon-top-industrial-2x-width: 140px;
$icon-top-industrial-2x-height: 120px;
$icon-top-industrial-2x-total-width: 644px;
$icon-top-industrial-2x-total-height: 554px;
$icon-top-industrial-2x-image: '/design/img/sprite@2x.png';
$icon-top-industrial-2x: (0px, 0px, 0px, 0px, 140px, 120px, 644px, 554px, '/design/img/sprite@2x.png', 'icon_top_industrial@2x', );
$icon-top-news-2x-name: 'icon_top_news@2x';
$icon-top-news-2x-x: 544px;
$icon-top-news-2x-y: 118px;
$icon-top-news-2x-offset-x: -544px;
$icon-top-news-2x-offset-y: -118px;
$icon-top-news-2x-width: 100px;
$icon-top-news-2x-height: 100px;
$icon-top-news-2x-total-width: 644px;
$icon-top-news-2x-total-height: 554px;
$icon-top-news-2x-image: '/design/img/sprite@2x.png';
$icon-top-news-2x: (544px, 118px, -544px, -118px, 100px, 100px, 644px, 554px, '/design/img/sprite@2x.png', 'icon_top_news@2x', );
$icon-top-phone-2x-name: 'icon_top_phone@2x';
$icon-top-phone-2x-x: 0px;
$icon-top-phone-2x-y: 498px;
$icon-top-phone-2x-offset-x: 0px;
$icon-top-phone-2x-offset-y: -498px;
$icon-top-phone-2x-width: 56px;
$icon-top-phone-2x-height: 56px;
$icon-top-phone-2x-total-width: 644px;
$icon-top-phone-2x-total-height: 554px;
$icon-top-phone-2x-image: '/design/img/sprite@2x.png';
$icon-top-phone-2x: (0px, 498px, 0px, -498px, 56px, 56px, 644px, 554px, '/design/img/sprite@2x.png', 'icon_top_phone@2x', );
$icon-top-recruit-2x-name: 'icon_top_recruit@2x';
$icon-top-recruit-2x-x: 424px;
$icon-top-recruit-2x-y: 238px;
$icon-top-recruit-2x-offset-x: -424px;
$icon-top-recruit-2x-offset-y: -238px;
$icon-top-recruit-2x-width: 108px;
$icon-top-recruit-2x-height: 108px;
$icon-top-recruit-2x-total-width: 644px;
$icon-top-recruit-2x-total-height: 554px;
$icon-top-recruit-2x-image: '/design/img/sprite@2x.png';
$icon-top-recruit-2x: (424px, 238px, -424px, -238px, 108px, 108px, 644px, 554px, '/design/img/sprite@2x.png', 'icon_top_recruit@2x', );
$spritesheet-width: 322px;
$spritesheet-height: 277px;
$spritesheet-image: '/design/img/sprite.png';
$spritesheet-sprites: ($icon-auto-products, $icon-auto-service, $icon-autoparts-white, $icon-cellular-white, $icon-company-white, $icon-importcar-white, $icon-ind-maker, $icon-industrial-white, $icon-mgn-autoparts, $icon-mgn-cellular, $icon-mgn-company, $icon-mgn-importcar, $icon-mgn-industrial, $icon-mgn-recruit, $icon-recruit-white, $icon-schedule-white, $icon-tel, $icon-tel-sp, $icon-top-autoparts, $icon-top-cellular, $icon-top-company, $icon-top-importcar, $icon-top-industrial, $icon-top-news, $icon-top-phone, $icon-top-recruit, );
$spritesheet: (322px, 277px, '/design/img/sprite.png', $spritesheet-sprites, );
$retina-spritesheet-width: 644px;
$retina-spritesheet-height: 554px;
$retina-spritesheet-image: '/design/img/sprite@2x.png';
$retina-spritesheet-sprites: ($icon-auto-products-2x, $icon-auto-service-2x, $icon-autoparts-white-2x, $icon-cellular-white-2x, $icon-company-white-2x, $icon-importcar-white-2x, $icon-ind-maker-2x, $icon-industrial-white-2x, $icon-mgn-autoparts-2x, $icon-mgn-cellular-2x, $icon-mgn-company-2x, $icon-mgn-importcar-2x, $icon-mgn-industrial-2x, $icon-mgn-recruit-2x, $icon-recruit-white-2x, $icon-schedule-white-2x, $icon-tel-2x, $icon-tel-sp-2x, $icon-top-autoparts-2x, $icon-top-cellular-2x, $icon-top-company-2x, $icon-top-importcar-2x, $icon-top-industrial-2x, $icon-top-news-2x, $icon-top-phone-2x, $icon-top-recruit-2x, );
$retina-spritesheet: (644px, 554px, '/design/img/sprite@2x.png', $retina-spritesheet-sprites, );

// These "retina group" variables are mappings for the naming and pairing of normal and retina sprites.
//
// The list formatted variables are intended for mixins like `retina-sprite` and `retina-sprites`.
$icon-auto-products-group-name: 'icon_auto_products';
$icon-auto-products-group: ('icon_auto_products', $icon-auto-products, $icon-auto-products-2x, );
$icon-auto-service-group-name: 'icon_auto_service';
$icon-auto-service-group: ('icon_auto_service', $icon-auto-service, $icon-auto-service-2x, );
$icon-autoparts-white-group-name: 'icon_autoparts_white';
$icon-autoparts-white-group: ('icon_autoparts_white', $icon-autoparts-white, $icon-autoparts-white-2x, );
$icon-cellular-white-group-name: 'icon_cellular_white';
$icon-cellular-white-group: ('icon_cellular_white', $icon-cellular-white, $icon-cellular-white-2x, );
$icon-company-white-group-name: 'icon_company_white';
$icon-company-white-group: ('icon_company_white', $icon-company-white, $icon-company-white-2x, );
$icon-importcar-white-group-name: 'icon_importcar_white';
$icon-importcar-white-group: ('icon_importcar_white', $icon-importcar-white, $icon-importcar-white-2x, );
$icon-ind-maker-group-name: 'icon_ind_maker';
$icon-ind-maker-group: ('icon_ind_maker', $icon-ind-maker, $icon-ind-maker-2x, );
$icon-industrial-white-group-name: 'icon_industrial_white';
$icon-industrial-white-group: ('icon_industrial_white', $icon-industrial-white, $icon-industrial-white-2x, );
$icon-mgn-autoparts-group-name: 'icon_mgn_autoparts';
$icon-mgn-autoparts-group: ('icon_mgn_autoparts', $icon-mgn-autoparts, $icon-mgn-autoparts-2x, );
$icon-mgn-cellular-group-name: 'icon_mgn_cellular';
$icon-mgn-cellular-group: ('icon_mgn_cellular', $icon-mgn-cellular, $icon-mgn-cellular-2x, );
$icon-mgn-company-group-name: 'icon_mgn_company';
$icon-mgn-company-group: ('icon_mgn_company', $icon-mgn-company, $icon-mgn-company-2x, );
$icon-mgn-importcar-group-name: 'icon_mgn_importcar';
$icon-mgn-importcar-group: ('icon_mgn_importcar', $icon-mgn-importcar, $icon-mgn-importcar-2x, );
$icon-mgn-industrial-group-name: 'icon_mgn_industrial';
$icon-mgn-industrial-group: ('icon_mgn_industrial', $icon-mgn-industrial, $icon-mgn-industrial-2x, );
$icon-mgn-recruit-group-name: 'icon_mgn_recruit';
$icon-mgn-recruit-group: ('icon_mgn_recruit', $icon-mgn-recruit, $icon-mgn-recruit-2x, );
$icon-recruit-white-group-name: 'icon_recruit_white';
$icon-recruit-white-group: ('icon_recruit_white', $icon-recruit-white, $icon-recruit-white-2x, );
$icon-schedule-white-group-name: 'icon_schedule_white';
$icon-schedule-white-group: ('icon_schedule_white', $icon-schedule-white, $icon-schedule-white-2x, );
$icon-tel-group-name: 'icon_tel';
$icon-tel-group: ('icon_tel', $icon-tel, $icon-tel-2x, );
$icon-tel-sp-group-name: 'icon_tel_sp';
$icon-tel-sp-group: ('icon_tel_sp', $icon-tel-sp, $icon-tel-sp-2x, );
$icon-top-autoparts-group-name: 'icon_top_autoparts';
$icon-top-autoparts-group: ('icon_top_autoparts', $icon-top-autoparts, $icon-top-autoparts-2x, );
$icon-top-cellular-group-name: 'icon_top_cellular';
$icon-top-cellular-group: ('icon_top_cellular', $icon-top-cellular, $icon-top-cellular-2x, );
$icon-top-company-group-name: 'icon_top_company';
$icon-top-company-group: ('icon_top_company', $icon-top-company, $icon-top-company-2x, );
$icon-top-importcar-group-name: 'icon_top_importcar';
$icon-top-importcar-group: ('icon_top_importcar', $icon-top-importcar, $icon-top-importcar-2x, );
$icon-top-industrial-group-name: 'icon_top_industrial';
$icon-top-industrial-group: ('icon_top_industrial', $icon-top-industrial, $icon-top-industrial-2x, );
$icon-top-news-group-name: 'icon_top_news';
$icon-top-news-group: ('icon_top_news', $icon-top-news, $icon-top-news-2x, );
$icon-top-phone-group-name: 'icon_top_phone';
$icon-top-phone-group: ('icon_top_phone', $icon-top-phone, $icon-top-phone-2x, );
$icon-top-recruit-group-name: 'icon_top_recruit';
$icon-top-recruit-group: ('icon_top_recruit', $icon-top-recruit, $icon-top-recruit-2x, );
$retina-groups: ($icon-auto-products-group, $icon-auto-service-group, $icon-autoparts-white-group, $icon-cellular-white-group, $icon-company-white-group, $icon-importcar-white-group, $icon-ind-maker-group, $icon-industrial-white-group, $icon-mgn-autoparts-group, $icon-mgn-cellular-group, $icon-mgn-company-group, $icon-mgn-importcar-group, $icon-mgn-industrial-group, $icon-mgn-recruit-group, $icon-recruit-white-group, $icon-schedule-white-group, $icon-tel-group, $icon-tel-sp-group, $icon-top-autoparts-group, $icon-top-cellular-group, $icon-top-company-group, $icon-top-importcar-group, $icon-top-industrial-group, $icon-top-news-group, $icon-top-phone-group, $icon-top-recruit-group, );

// The provided mixins are intended to be used with the array-like variables
//
// .icon-home {
//   @include sprite-width($icon-home);
// }
//
// .icon-email {
//   @include sprite($icon-email);
// }
//
// Example usage in HTML:
//
// `display: block` sprite:
// <div class="icon-home"></div>
//
// To change `display` (e.g. `display: inline-block;`), we suggest using a common CSS class:
//
// // CSS
// .icon {
//   display: inline-block;
// }
//
// // HTML
// <i class="icon icon-home"></i>
@mixin sprite-width($sprite) {
  width: nth($sprite, 5);
}

@mixin sprite-height($sprite) {
  height: nth($sprite, 6);
}

@mixin sprite-position($sprite) {
  $sprite-offset-x: nth($sprite, 3);
  $sprite-offset-y: nth($sprite, 4);
  background-position: $sprite-offset-x  $sprite-offset-y;
}

@mixin sprite-image($sprite) {
  $sprite-image: nth($sprite, 9);
  background-image: url(#{$sprite-image});
}

@mixin sprite($sprite) {
  @include sprite-image($sprite);
  @include sprite-position($sprite);
  @include sprite-width($sprite);
  @include sprite-height($sprite);
}

// The `retina-sprite` mixin sets up rules and a media query for a sprite/retina sprite.
//   It should be used with a "retina group" variable.
//
// The media query is from CSS Tricks: https://css-tricks.com/snippets/css/retina-display-media-query/
//
// $icon-home-group: ('icon-home', $icon-home, $icon-home-2x, );
//
// .icon-home {
//   @include retina-sprite($icon-home-group);
// }
@mixin sprite-background-size($sprite) {
  $sprite-total-width: nth($sprite, 7);
  $sprite-total-height: nth($sprite, 8);
  background-size: $sprite-total-width $sprite-total-height;
}

@mixin retina-sprite($retina-group) {
  $normal-sprite: nth($retina-group, 2);
  $retina-sprite: nth($retina-group, 3);
  @include sprite($normal-sprite);

  @media (-webkit-min-device-pixel-ratio: 2),
         (min-resolution: 192dpi) {
    @include sprite-image($retina-sprite);
    @include sprite-background-size($normal-sprite);
  }
}

// The `sprites` mixin generates identical output to the CSS template
//   but can be overridden inside of SCSS
//
// @include sprites($spritesheet-sprites);
@mixin sprites($sprites) {
  @each $sprite in $sprites {
    $sprite-name: nth($sprite, 10);
    .#{$sprite-name} {
      @include sprite($sprite);
    }
  }
}

// The `retina-sprites` mixin generates a CSS rule and media query for retina groups
//   This yields the same output as CSS retina template but can be overridden in SCSS
//
// @include retina-sprites($retina-groups);
@mixin retina-sprites($retina-groups) {
  @each $retina-group in $retina-groups {
    $sprite-name: nth($retina-group, 1);
    .#{$sprite-name} {
      @include retina-sprite($retina-group);
    }
  }
}
