.image-one {
  margin: 3.5em 0 2.7em;

  @include below($sm) {
    margin: 1.8em 0 0;
  }

  .img {
    margin: 0;
    text-align: center;

    @include below($sm) {
      width: auto !important;
      text-align: center;
    }
  }

  .caption {
    display: block;
    text-align: center;
    margin: 8px 0 0;
  }
}