.link-button {
  display: block;
  background: palette('corp');
  color: #fff;
  text-decoration: none;
  text-align: center;
  font-size: 1.8rem;
  padding: 30px 5px;
  transition: background 0.15s $ease-out-quint;
  position: relative;
  box-sizing: border-box;
  width: 100%;

  @include below($sm) {
    font-size: 1.4rem;
    padding: 15px 10px;
  }

  &::before {
    content: '';
    display: block;
    @include icon-ion('f3d1');
    position: absolute;
    top: 50%;
    right: 10px;
    transform: translateY(-50%);
    color: #fff;
    font-size: 2.8rem;

    @include below($sm) {
      font-size: 2rem;
    }
  }

  &:hover,
  &:active,
  &:focus {
    background: palette('corp', 'light');
  }

  &.-current {
    border: 1px solid palette('corp');
    background: #fff;
    color: palette('corp');
  }

  // 戻るボタン
  &.-back {
    &::before {
      right: auto;
      left: 10px;
      @include icon-ion('f3cf');
    }
  }

  &.-narrow {
    padding: 18px 5px;

    @include below($sm) {
      padding: 15px 10px;
    }
  }
}