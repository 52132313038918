.list-panel {
  display: flex;
  flex-wrap: wrap;

  @include below($sm) {
    display: block;
  }

  > .panel {
    background: #fff;
    border: 1px solid #ccc;
    box-sizing: border-box;
    width: calc((100% - 40px * 2) / 3);
    margin: 20px 40px 0 0;
    padding: 15px;

    @include above($sm) {
      &:nth-child(3n+3) {
        margin-right: 0;
      }
    }

    @include below($sm) {
      width: auto;
      margin: 12px 0 0;
      padding: 30px 20px 20px;
    }

    > p {
      margin: 0;
    }

    > .logo {
      margin: 15px 0 0;
      text-align: center;
      min-height: 80px;
      display: flex;
      align-items: center;
      justify-content: center;

      @include below($sm) {
        margin: 0;
        min-height: 0;
      }
    }

    > .name {
      margin: 10px 0 0;
      text-align: center;

      @include below($sm) {
        margin: 18px 0 0;
      }
    }

    > .outline {
      margin: 10px 0 0;
      padding: 10px;
      line-height: 1.5;

      @include below($sm) {
        padding: 0;
      }
    }
  }
}