@charset "UTF-8";

// Ionicons アイコン出力
// -----------------------------------------------------------
//    $unicode: Ionicons に割り当てられた Unicode
//
//    使用例：
//    p.foo::before {
//        @include icon-ion('f126');
//    }
//
//    注意：
//    icon-ion の引数にはエスケープなしの文字コードを直接入力する。Ionicons の変数を指定するとコンパイル時に文字化けしてしまう
// -----------------------------------------------------------
@mixin icon-ion($unicode) {
  content: unquote('\"') + unquote(str-insert($unicode, '\\', 1)) + unquote('\"'); // gulp-sass でコンパイルした時に文字コードが文字化けするのを回避
  @extend %ion;
}
