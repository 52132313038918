@charset "UTF-8";

// 禁則処理を行うユーティリティクラス
// -----------------------------------------------------------
%wordwrap {
  // word-break プロパティは禁則処理の指定をするが、
  // word-break: break-all ですべての禁則処理を無効化してしまうと、
  // 英単語の途中でも改行されてしまうなど問題があるため word-break は使用しない。

  // overflow-wrap: break-word は通常は禁則処理を有効とし、
  // どうしてもはみ出る場合のみ禁則処理を無効化する

  word-wrap: break-word;     // overflow-wrap の旧名称。元々は IE の独自実装
  overflow-wrap: break-word; // word-wrap プロパティが CSS3 で改称されたもの
}