.head-office {
  display: flex;
  margin: 45px 0 0;

  @include below($sm) {
    flex-direction: column;
  }

  > .cont {
    order: 2;
    background: palette('corp');
    color: #fff;
    padding: 25px 30px 30px;
    flex-grow: 1;
    position: relative;

    @include below($sm) {
      padding: 15px;
    }

    > .hdg {
      font-weight: normal;
      font-size: 2.6rem;
      border-bottom: 1px solid #fff;
      padding: 0 0 5px;
      margin: 0;

      @include below($sm) {
        font-size: 2rem;
      }
    }

    > .gmap {
      position: absolute;
      top: 30px;
      right: 30px;
      margin: 0;

      @include below($sm) {
        position: static;
        margin: 10px 0 0;
        text-align: right;
      }

      a {
        color: inherit;
      }
    }

    > .info {
      margin: 10px 0 0;
      display: flex;
      flex-wrap: wrap;
      align-items: flex-start;

      > dt {
        width: 75px;
        background: #fff;
        text-align: center;
        color: palette('corp');
        margin: 10px 0 0;
        padding: 3px 5px;
        box-sizing: border-box;

        @include below($sm) {
          padding: 1px 5px;
        }
      }

      > dd {
        width: calc(100% - (75px + 15px));
        margin: 10px 0 0 15px;
        padding: 3px 0;

        @include below($sm) {
          padding: 1px 0;
        }

        a {
          color: inherit;
        }
      }
    }

    > .panel {
      margin: 35px 0 0;
      background: #fff;
      color: palette('corp');
      padding: 15px;

      > ul {
        font-size: 1.8rem;

        @include below($sm) {
          font-size: 1.6rem;
        }

        > li {
          position: relative;
          padding-left: 1em;

          &::before {
            content: '\30FB';
            position: absolute;
            top: 0;
            left: 0;
          }
        }
      }
    }
  }

  > .img {
    flex-shrink: 0;
    order: 1;
  }
}