.l-mobile-globalnav {
  @include above($sm) {
    display: none;
  }

  background: #fff;

  > ul {
    border-top: 1px solid palette('corp');

    > .collapse {
      > .opener {
        border-width: 0 0 1px;
        border-style: solid;
        border-color: palette('corp');
        background: #fff;
        width: 100%;
        box-sizing: border-box;
        padding: 20px 15px;
        margin: 0;
        text-align: center;
        cursor: pointer;
        color: palette('corp');
        font-size: 1.6rem;
        line-height: 1.4;
        transition: background 0.15s $ease-out-quint;
        position: relative;

        &::before {
          content: '';
          display: block;
          position: absolute;
          top: 50%;
          left: 10px;
          transform: translateY(-50%);
        }

        &::after {
          content: '';
          display: block;
          @include icon-ion('f101');
          position: absolute;
          top: 50%;
          right: 10px;
          transform: translateY(-50%);
          font-size: 2.8rem;
        }

        &:hover,
        &:active,
        &:focus {
          background: palette('corp', 'superlight');
        }

        // 開いている状態
        &.-open {
          &::after {
            @include icon-ion('f1fb');
          }
        }

        // カテゴリ別アイコン
        &.-autoparts::before {
          @include retina-sprite($icon-mgn-autoparts-group);
        }
        &.-industrial::before {
          @include retina-sprite($icon-mgn-industrial-group);
        }
        &.-cellular::before {
          @include retina-sprite($icon-mgn-cellular-group);
        }
        &.-importcar::before {
          @include retina-sprite($icon-mgn-importcar-group);
        }
        &.-recruit::before {
          @include retina-sprite($icon-mgn-recruit-group);
        }
        &.-company::before {
          @include retina-sprite($icon-mgn-company-group);
        }
      }

      > .panel {
        border-bottom: 1px solid palette('corp');

        > li {
          border-bottom: 1px dotted #999;

          &:last-child {
            border-bottom: none;
          }

          > a {
            display: block;
            color: #333;
            text-decoration: none;
            background-color: #fafafa;
            padding: 13px 15px;
            position: relative;
            transition: all 0.15s $ease-out-quint;

            &::before {
              content: '';
              display: block;
              @include icon-ion('f3d1');
              position: absolute;
              top: 50%;
              right: 10px;
              transform: translateY(-50%);
              color: #333;
              font-size: 2rem;
            }

            &:hover,
            &:active,
            &:focus {
              background-color: #e5e5e5;
            }
          }
        }
      }
    }
  }
}