.index-staff {
  margin: 40px 0 0;

  @include below($sm) {
    margin: 25px 0 0;
  }

  > ul {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    @include below($sm) {
      display: block;
    }

    > li {
      margin: 20px 0 0;
      width: calc((100% - 40px * 1) / 2);

      @include below($sm) {
        margin: 15px 0 0;
        width: auto;
      }

      > a {
        display: block;
        position: relative;

        &:hover,
        &:active,
        &:focus {
          &::before {
            opacity: 0.25;
          }
        }

        &::before {
          content: '';
          display: block;
          width: 100%;
          height: 100%;
          background: #fff;
          opacity: 0;
          position: absolute;
          top: 0;
          left: 0;
          transition: opacity 0.15s $ease-out-quint;
        }

        > .img {
          overflow: hidden;

          > span {
            background-position: 50% 50%;
            background-repeat: no-repeat;
            background-size: cover;
            display: block;
            padding-top: 80%;

            @include below($sm) {
              padding-top: 85%;
            }
          }
        }

        > .info {
          position: absolute;
          left: 0;
          bottom: 0;
          width: 100%;
          color: #fff;
          text-align: center;
          padding: 20px 15px 15px;
          box-sizing: border-box;
          line-height: 1.4;

          @include below($sm) {
            padding: 13px 15px 10px;
          }

          &::after {
            content: '';
            display: block;
            @include icon-ion('f3d1');
            position: absolute;
            top: 50%;
            right: 10px;
            transform: translateY(-50%);
            color: #fff;
            font-size: 2.8rem;

            @include below($sm) {
              font-size: 2rem;
            }
          }

          > span {
            display: block;

            &.position {
              font-size: 2rem;

              @include below($sm) {
                font-size: 1.4rem;
              }
            }

            &.name {
              font-size: 2.6rem;

              @include below($sm) {
                font-size: 1.6rem;
              }

              > span {
                font-size: 2rem;

                @include below($sm) {
                  font-size: 1.3rem;
                }
              }
            }

            &.since {
              margin: 5px 0 0;
              font-size: 1.6rem;

              @include below($sm) {
                font-size: 1.3rem;
              }
            }
          }
        }
      }

      // 部署別色設定
      &.-autoparts {
        .info {
          background-color: rgba(palette('autoparts'), 0.9);
        }
      }
      &.-industrial {
        .info {
          background-color: rgba(palette('industrial'), 0.9);
        }
      }
      &.-cellular {
        .info {
          background-color: rgba(palette('cellular'), 0.9);
        }
      }
      &.-importcar {
        .info {
          background-color: rgba(palette('importcar'), 0.9);
        }
      }
    }
  }
}