.footer-sitemap {
  background: url('../img/footer-sitemap_bg.gif') 0 0 repeat;

  > .inner {
    display: flex;
    justify-content: space-between;
    max-width: 1100px;
    margin: 0 auto;
    padding: 35px 0;

    @include below($sm) {
      display: block;
      padding: 20px 15px;
    }

    a {
      &[target="_blank"] {
        &::after {
          content: '';
          display: inline-block;
          @include icon-fa('f08e');
          margin-left: 6px;
        }
      }
    }

    > .col {
      width: calc((100% - 20px * 3) / 4);

      @include below($sm) {
        width: auto;
      }

      > ul {

        &.-prim {
          line-height: 1.3;

          @include below($sm) {
            display: none;
          }

          > li {
            font-size: 2rem;
            margin: 0 0 13px;
            > ul {
              margin: 10px 0 18px;
              > li {
                font-size: 1.4rem;
                margin: 0 0 8px;
                position: relative;
                padding-left: 1em;
                &::before {
                  content: '\30FB';
                  display: block;
                  position: absolute;
                  top: 0;
                  left: 0;
                  color: palette('corp');
                }
              }
            }
          }
        }

        &.-seco {
          > li {
            @include below($sm) {
              position: relative;
              padding-left: 1em;
              margin: 0 0 3px;

              &::before {
                content: '';
                display: block;
                @include icon-ion('f3d1');
                position: absolute;
                top: 0.1em;
                left: 0;
                color: palette('corp');
              }
            }

            &.-sm {
              @include above($sm) {
                display: none;
              }
            }
          }
        }
      }
    }
  }
}