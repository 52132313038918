@charset "UTF-8";

// テーマカラー
// -----------------------------------------------------------
$siteColor: #2c6392;

$palettes: (
  'text': (
    'default' : #333,
  ),
  'corp': (
    'default'    : #3d568c,
    'light'      : #4e679b,
    'superlight' : #d2dff8,
  ),
  'autoparts': (
    'default' : #3c36b2,
  ),
  'industrial': (
    'default' : #55b84a,
  ),
  'cellular': (
    'default' : #fbac3c,
  ),
  'importcar': (
    'default' : #ec4730,
  )
);


// 基本文字色
// -----------------------------------------------------------
$baseFontColor: palette('text');


// リンク関連
// -----------------------------------------------------------
// $baseLinkColor: palette('link');