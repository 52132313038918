@charset "UTF-8";

// フロート解除用プレースホルダとユーティリティクラス
// -----------------------------------------------------------
// 原則、プレースホルダを extend で使用する
%clearfix {
  &:after {
    content: "";
    display: block;
    clear: both;
  }
}

._clear {
  @extend %clearfix;
}