.category-mv {
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-size: cover;
  border-bottom: 1px solid #000;
  padding: 45px 30px;
  margin-top: -2px;

  @include below($sm) {
    padding: 12px 15px 18px;
    margin-top: 0;
  }

  &.-leaf {
    padding: 30px;

    @include below($sm) {
      padding: 12px 15px 18px;
    }
  }

  &::before {
    content: '';
    display: block;
    margin: 0 auto 7px;

    @include below($sm) {
      transform: scale(0.6);
      transform-origin: 50% 100%;
    }
  }

  > .hdg {
    font-size: 4.8rem;
    text-align: center;
    line-height: 1.4;
    color: #fff;
    letter-spacing: 0.08em;
    position: relative;
    margin: 0;
    font-weight: normal;

    @include below($sm) {
      font-size: 2.6rem;
    }

    > .en {
      display: block;
      font-size: 2.4rem;

      @include below($sm) {
        font-size: 1.2rem;
      }
    }
  }

  > .lead {
    text-align: center;
    color: #fff;
    font-size: 2.4rem;
    margin: 30px 0 0;

    @include below($sm) {
      font-size: 1.4rem;
      margin: 20px 0 0;
    }

    br:not(.nbr) {
      @include above($sm) {
        display: none;
      }
    }
  }

  > .nav {
    display: flex;
    justify-content: center;
    margin: 55px 0 0;

    @include below($sm) {
      display: block;
      margin: 30px 0 0;
    }

    > li {
      margin: 0 15px;

      @include below($sm) {
        margin: 0 0 10px;
      }

      > a {
        display: block;
        border: 1px solid #fff;
        background: rgba(#242425, 0.6);
        color: #fff;
        text-decoration: none;
        width: 290px;
        box-sizing: border-box;
        text-align: center;
        padding: 30px 15px;
        font-size: 1.8rem;
        transition: background 0.15s $ease-out-quint;
        position: relative;

        @include below($sm) {
          width: auto;
          padding: 12px 15px;
          font-size: 1.6rem;
        }

        &::before {
          content: '';
          display: block;
          @include icon-ion('f3d1');
          position: absolute;
          top: 50%;
          right: 10px;
          transform: translateY(-50%);
          color: #fff;
          font-size: 2.4rem;

          @include below($sm) {
            font-size: 2rem;
          }
        }

        &:hover,
        &:active,
        &:focus {
          background: rgba(#242425, 0.3);
        }
      }
    }
  }

  // カテゴリ別指定
  &.-company {
    background-image: url('../img/category-mv_bg_company.jpg');
    @include below($sm) {
      background-image: url('../img/category-mv_bg_company_sp.jpg');
    }
    &::before {
      @include retina-sprite($icon-company-white-group);
    }
  }
  &.-recruit {
    background-image: url('../img/category-mv_bg_recruit.jpg');
    background-position: 50% 0;
    @include below($sm) {
      background-image: url('../img/category-mv_bg_recruit_sp.jpg');
    }
    &:not(.-leaf) {
      background-image: url('../img/category-mv_bg_recruit_top.jpg');
      background-position: 50% 0;
      @include below($sm) {
        background-image: url('../img/category-mv_bg_recruit_top_sp.jpg');
        background-size: 100% auto;
        background-color: #262626;
      }
    }
    &::before {
      @include retina-sprite($icon-recruit-white-group);
    }
  }
  &.-autoparts {
    background-image: url('../img/category-mv_bg_autoparts.jpg');
    @include below($sm) {
      background-image: url('../img/category-mv_bg_autoparts_sp.jpg');
    }
    &:not(.-leaf) {
      padding-top: 164px;
      padding-bottom: 145px;
      background-image: url('../img/category-mv_bg_autoparts_top.jpg');
      @include below($sm) {
        padding: 12px 15px 18px;
        background-image: url('../img/category-mv_bg_autoparts_top_sp.jpg');
      }
    }
    &::before {
      @include retina-sprite($icon-autoparts-white-group);
    }
  }
  &.-industrial {
    background-image: url('../img/category-mv_bg_industrial.jpg');
    @include below($sm) {
      background-image: url('../img/category-mv_bg_industrial_sp.jpg');
    }
    &:not(.-leaf) {
      padding-top: 164px;
      padding-bottom: 145px;
      background-image: url('../img/category-mv_bg_industrial_top.jpg');
      @include below($sm) {
        padding: 12px 15px 18px;
        background-image: url('../img/category-mv_bg_industrial_top_sp.jpg');
      }
    }
    &::before {
      @include retina-sprite($icon-industrial-white-group);
    }
  }
}