.top-company {
  display: flex;

  @include below($sm) {
    display: block;
  }

  > .cont {
    order: 1;
    width: 50%;
    box-sizing: border-box;
    padding: 35px 50px 50px;
    min-height: 416px;
    background:
      url('../img/top-company_cont_bg.png') 0 0 repeat,
      url('../img/top-company_cont_logo.png') 104% 50% no-repeat,
      #ebebeb;

    @include below($sm) {
      width: auto;
      padding: 15px 15px 25px;
      min-height: 0;
      background: #f5f5f5;
      color: #333;
    }

    > .hdg {
      border-bottom: 1px solid palette('corp');
      font-size: 2.4rem;
      padding: 0 0 10px 85px;
      font-weight: normal;
      position: relative;
      color: palette('corp');

      @include below($sm) {
        display: none;
      }

      &::before {
        content: '';
        display: block;
        @include retina-sprite($icon-top-company-group);
        position: absolute;
        top: 8px;
        left: 2px;
      }

      > .ja {
        display: block;
        font-size: 3.6rem;
      }

      > .en {
        display: block;
        font-size: 1.8rem;
        letter-spacing: 0.3em;
        margin: 0;
      }
    }

    > p {
      font-size: 1.8rem;

      @include below($sm) {
        margin: 0;
        font-size: 1.4rem;
      }
    }
  }

  > .media {
    width: 50%;
    flex-shrink: 0;
    order: 2;

    @include below($sm) {
      display: none;
    }

    > .img {
      display: block;
      height: 100%;
      background-position: 50% 0;
      background-repeat: no-repeat;
      background-size: cover;
    }
  }
}