.staff-interview {
  margin: 50px 0 0;
  display: flex;
  justify-content: space-between;

  @include below($sm) {
    flex-direction: column;
    margin: 35px 0 0;
  }

  > .interview {
    order: 2;
    width: 480px;

    @include below($sm) {
      order: 1;
      width: auto;
    }

    > .hdg {
      font-size: 2.2rem;
      color: palette('corp');
      margin: 25px 0 0;
      padding: 25px 0 0;
      border-top: 1px dotted #777;
      font-weight: normal;

      @include below($sm) {
        font-size: 1.8rem;
      }

      &:first-child {
        margin-top: 0;
        padding-top: 0;
        border-top: none;
      }

      > span {
        @include below($sm) {
          display: inline-block;
          border-left: 3px solid palette('corp');
          padding-left: 8px;
        }
      }
    }

    > p {
      margin: 1em 0 0;
    }
  }

  > .photo {
    order: 1;
    flex-shrink: 0;
    width: 250px;

    @include below($sm) {
      order: 2;
      width: auto;
      margin: 25px -15px 0;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
    }

    > .img {
      margin: 0 0 1px;

      @include below($sm) {
        width: calc((100% - 1px * 1) / 2);
      }
    }
  }

  > .schedule {
    order: 3;
    width: 270px;

    @include below($sm) {
      margin: 25px -15px 0;
      width: auto;
    }
  }
}