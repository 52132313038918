.header-ci {
  a {
    transition: opacity 0.2s $ease-out-quint;

    &:hover,
    &:active,
    &:focus {
      opacity: 0.8;
    }
  }

  img {
    width: 289px;
    height: auto;

    @include below($sm) {
      width: 180px;
    }
  }
}