.recruit-staff {
  margin: -65px 0 100px;
  padding: 15px 0 400px;
  border-bottom: 1px solid palette('corp');
  background-image: url('../img/recruit-staff_bg.jpg');
  background-position: 50% 0;
  background-repeat: no-repeat;
  background-size: cover;

  @include below($sm) {
    background-image: url('../img/recruit-staff_bg_sp.jpg');
    background-position: 50% 50%;
    background-repeat: no-repeat;
    background-size: cover;
    padding: 45px 15px 30px;
    min-height: 270px;
    margin: -65px 0 50px;
    border-bottom: none;
  }

  > .text {
    color: #fff;
    box-sizing: border-box;

    > .hdg-page {
      color: #fff;

      &::after {
        background: #fff;
      }
    }

    > p {
      text-align: center;
      font-size: 1.8rem;

      @include below($sm) {
        font-size: 1.4rem;
      }
    }
  }

  > .nav-button {
    margin: 25px 0 0;

    @include below($sm) {
      margin: 45px 15px 0;
    }

    .link-button2 {
      border-color: #fff;

      @include below($sm) {
        border-color: palette('corp');
      }
    }
  }
}